import styled from "styled-components";
import { TextField as MUITextField } from "@mui/material";
import { colors } from "@lumonus/gc-ui";

const Input = styled(MUITextField)`
  width: 100%;
  border: ${colors.primary.base};
  ${(props) =>
    props.disabled
      ? {
          backgroundColor: colors.neutral.lightestGrey,
        }
      : ""};
`;

export default Input;
