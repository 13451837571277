import React from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide, { SlideProps } from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import DoneIcon from "@mui/icons-material/Done";
import GreenButton from "components/base/GreenButton";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & SlideProps,
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface Props {
  confirmationText: string;
  cancelText: string;
  isVisible: boolean;
  title: string;
  bodyText: string | JSX.Element;
  setIsVisible: (value: boolean) => void;
  onConfirmation: any;
  onCancel?: any;
}
const ConfirmationDialog = (props: Props) => {
  const {
    confirmationText,
    cancelText,
    isVisible,
    title,
    bodyText,
    setIsVisible,
    onConfirmation,
    onCancel,
  } = props;

  return (
    <div>
      <Dialog
        open={isVisible}
        TransitionComponent={Transition}
        keepMounted
        onClose={() => {
          setIsVisible(false);
          onCancel && onCancel();
        }}
        aria-labelledby="confirmation-dialog-title"
        aria-describedby="confirmation-dialog-description"
      >
        <DialogTitle id="confirmation-dialog-title">{title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="confirmation-dialog-description">
            {bodyText}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <GreenButton
            data-test-id="btn-confirmation-dialog-cancel"
            onClick={() => {
              setIsVisible(false);
              onCancel && onCancel();
            }}
          >
            {cancelText}
          </GreenButton>
          <GreenButton
            solidgreen={"true"}
            data-test-id="btn-confirmation-dialog-confirm"
            onClick={() => {
              setIsVisible(false);
              onConfirmation();
            }}
            variant="contained"
            endIcon={<DoneIcon />}
          >
            {confirmationText}
          </GreenButton>
        </DialogActions>
      </Dialog>
    </div>
  );
};
export default ConfirmationDialog;
