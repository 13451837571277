import React, { Fragment, useEffect } from "react";
import { FieldProps } from "formik";
import { Grid, InputAdornment, IconButton } from "@mui/material";
import FormRow from "components/base/FormRow";
import Button from "components/base/Button";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import { doseName } from "i18n/constants";
import { StyledTextField, StyledDiv } from "components/formFields/DoseShared";
import styled from "styled-components";

const StyledInputAdornment = styled(InputAdornment)`
  margin-left: 0px;
`;

export enum DoseOperation {
  Delete,
  Add,
}

interface Props extends FieldProps {
  label: string;
  isActive?: boolean;
  handleMutation: any;
}

export const parseDose = (value: any) => {
  const jsonDoseValue = typeof value === "string" ? JSON.parse(value) : value;
  if (Array.isArray(jsonDoseValue)) return jsonDoseValue;
  // If the dose has janked out and is not an array, return as an array
  return [jsonDoseValue];
};

const calcDoseValue = (index: number, doseValues: any, val: any) => {
  let floatValue: string | number = parseFloat(val);
  if (floatValue === null || isNaN(floatValue)) floatValue = "";
  return [
    ...doseValues.slice(0, index),
    floatValue,
    ...doseValues.slice(index + 1),
  ];
};

const DoseField = ({ field, form, label, handleMutation, isActive }: Props) => {
  const { onBlur, name, value } = field;
  const doseValues = parseDose(value);
  const { setFieldValue } = form;

  const handleBlur = (event: React.ChangeEvent<any>, index: number) => {
    onBlur(event);
    const newValue = calcDoseValue(index, doseValues, event.target.value);
    handleMutation && handleMutation(name, JSON.stringify(newValue));
  };

  const handleChange = (
    { target: { value: newVal } }: React.ChangeEvent<any>,
    index: number
  ) => {
    const newValue = calcDoseValue(index, doseValues, newVal);
    setFieldValue(name, newValue);
  };

  // Trigger handleMutation when the user stops typing
  useEffect(() => {
    const mutateAfterNoInput = setTimeout(() => {
      if (isActive)
        handleMutation && handleMutation(name, JSON.stringify(doseValues));
    }, 1000);
    return () => clearTimeout(mutateAfterNoInput);
    // eslint-disable-next-line
  }, [doseValues]);

  const addDose = (values: (string | number)[]) => {
    if (values.length > 4) {
      return;
    }
    const idx = Math.ceil(values.length / 2);
    const newValue = [...values];
    newValue.splice(idx, 0, "");
    setFieldValue(name, newValue);
    handleMutation(
      name,
      JSON.stringify(newValue),
      true,
      DoseOperation.Add,
      idx
    );
  };

  const deleteDose = (index: number, values: (string | number)[]) => {
    if (values.length <= 1) {
      return;
    }
    const newValue = [...values];
    newValue.splice(index, 1);
    setFieldValue(name, newValue);
    handleMutation(
      name,
      JSON.stringify(newValue),
      true,
      DoseOperation.Delete,
      index
    );
  };

  return (
    <Fragment>
      <FormRow label={label} name={name} disabled={!isActive}>
        <StyledDiv>
          {doseValues.map((v: any, index: number) => (
            <StyledTextField
              name={name}
              placeholder={doseName(doseValues.length)[index]}
              key={index}
              value={v || ""}
              onChange={(e) => handleChange(e, index)}
              id={`dose-${index}`}
              variant="outlined"
              onBlur={(e) => handleBlur(e, index)}
              size="medium"
              disabled={!isActive}
              type="number"
              InputProps={{
                inputProps: {
                  min: 0,
                  "data-test-id": `${name}-input-${index}`,
                },
                endAdornment:
                  index > 0 ? (
                    <StyledInputAdornment position="end">
                      <IconButton
                        aria-label="remove"
                        edge="end"
                        onClick={() => deleteDose(index, doseValues)}
                        disabled={!isActive}
                        data-test-id={`btn-remove-dose-${index}`}
                        size="large"
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    </StyledInputAdornment>
                  ) : null,
              }}
            />
          ))}
        </StyledDiv>
      </FormRow>

      <Grid container item justifyContent="space-around" xs={5}>
        <Button
          disabled={doseValues.length >= 4 || !isActive}
          onClick={() => addDose(doseValues)}
          data-test-id="btn-add-dose"
        >
          Add Dose
        </Button>
      </Grid>
    </Fragment>
  );
};

export default DoseField;
