import React, { useContext } from "react";
import styled from "styled-components";
import {
  AppBar,
  Toolbar,
  Typography,
  MenuItem,
  Menu,
  Button,
} from "@mui/material";
import { Link, useLocation, useHistory } from "react-router-dom";
import { MainLogo, favicon } from "../themes/theme";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import ExitToAppOutlinedIcon from "@mui/icons-material/ExitToAppOutlined";
import { LOGOUT } from "graphql/UserQueries";
import { useMutation } from "@apollo/client";
import { navigateToExternalURL } from "shared/utils";
import UserContext from "contexts/UserContext";
import EnvironmentBanner from "./EnvironmentBanner";
import { colors } from "@lumonus/gc-ui";
import Favicon from "react-favicon";

const StyledAppBar = styled(AppBar)`
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
`;

const StyledMenu = styled(Menu)`
  top: 6.5vh !important;
`;

const StyledMenuItem = styled(MenuItem)`
  color: ${colors.neutral.darkGrey};
  font-size: large;
`;

const StyledToolbar = styled(Toolbar)`
  background-color: white;
`;

const StyledButton = styled(Button)`
  color: ${colors.neutral.black};
  padding: 18px 22px 14px 22px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
  margin-right: 10px;
  margin-left: 10px;
`;

const StyledLink = styled(Link)<{ $isActive: boolean }>`
  color: ${colors.neutral.black};
  border-bottom: ${(props) =>
    props.$isActive ? `4px solid ${colors.primary.base}` : "none"};
  padding: 18px 22px 14px 22px;
  line-height: 24px;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  background-color: transparent;
  margin-right: 10px;
  margin-left: 10px;
  &:hover {
    text-decoration: none;
    color: ${colors.neutral.black};
  }
`;

const StyledDropDownLink = styled(StyledButton)`
  margin: auto;
  width: 100%;
  padding: 10px;
  overflow: auto;
  text-align: center;
`;

const StyleTypography = styled(Typography)`
  color: black;
`;

const StyledMenuTypography = styled(StyleTypography)`
  flex-grow: 1;
  cursor: pointer;
`;

const StyleDropDownTypography = styled(StyleTypography)`
  color: ${colors.neutral.grey}
  vertical-align: middle;
  text-transform: none;
`;

const StyledExitToAppOutlinedIcon = styled(ExitToAppOutlinedIcon)`
  vertical-align: middle;
  margin-right: 5px;
`;

const IconButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  transform: rotate(0deg);
  overflow: hidden;
  transition: all 0.8s ease-out;
  cursor: pointer;
  width: 200px;

  svg {
    height: 59px;
    width: auto;
  }
`;

const TopNav = (): JSX.Element => {
  const location = useLocation();
  const history = useHistory();
  const [
    anchorDropDown,
    setAnchorDropDown,
  ] = React.useState<null | HTMLElement>(null);
  const handleProfileMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorDropDown(event.currentTarget);
  };
  const isActiveItem = (itemLocation: string): boolean => {
    return location.pathname.startsWith(itemLocation);
  };
  const isMenuOpen = Boolean(anchorDropDown);
  const handleMenuClose = () => {
    setAnchorDropDown(null);
  };

  const user = useContext(UserContext);

  const [logout] = useMutation(LOGOUT);

  const renderMenu = (
    <StyledMenu
      anchorEl={anchorDropDown}
      anchorOrigin={{ vertical: "top", horizontal: "right" }}
      id="top-nav"
      keepMounted
      transformOrigin={{ vertical: "top", horizontal: "right" }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      <MenuItem onClick={handleMenuClose}>
        <StyledDropDownLink
          onClick={() => {
            navigateToExternalURL(
              "https://login.windows.net/common/oauth2/logout"
            );
            logout();
          }}
        >
          <StyleDropDownTypography>
            <StyledExitToAppOutlinedIcon fontSize="large" />
            Logout
          </StyleDropDownTypography>
        </StyledDropDownLink>
      </MenuItem>
    </StyledMenu>
  );

  return (
    <StyledAppBar position="static">
      <Favicon url={favicon} />
      <StyledToolbar>
        <IconButtonWrapper
          data-test-id="app-icon"
          onClick={() => history.push("/home")}
        >
          <MainLogo />
        </IconButtonWrapper>
        <StyledMenuTypography variant="h6">
          {user.roAccess && (
            <StyledLink to="/search" $isActive={isActiveItem("/search")}>
              {user ? "Search" : "Login"}
            </StyledLink>
          )}
          {user.roAccess && (
            <StyledLink
              data-test-id="review-nav"
              to="/review"
              $isActive={isActiveItem("/review")}
            >
              Review (beta)
            </StyledLink>
          )}
          {user.moAccess && (
            <StyledLink
              to="/protocol_search"
              $isActive={isActiveItem("/protocol_search")}
            >
              Protocol Templates
            </StyledLink>
          )}
          {user.moAccess && (
            <StyledLink
              data-test-id="view-formulary"
              to="/formulary"
              $isActive={isActiveItem("/formulary")}
            >
              Formulary
            </StyledLink>
          )}
        </StyledMenuTypography>
        <EnvironmentBanner />
        {user && (
          <StyledMenuItem onClick={handleProfileMenuOpen}>
            <StyledMenuTypography variant="subtitle1">
              Hi, {user.firstName}&nbsp;{user.lastName}&nbsp;
            </StyledMenuTypography>
            <AccountCircleOutlinedIcon fontSize="large" />
          </StyledMenuItem>
        )}
      </StyledToolbar>
      {renderMenu}
    </StyledAppBar>
  );
};

export default TopNav;
