import React from "react";
// eslint-disable-next-line
import { FieldProps, getIn, useFormikContext } from "formik";

import FormControl from "@mui/material/FormControl";
import MenuItem from "@mui/material/MenuItem";

import Select from "components/base/Select";
import FormRow from "components/base/FormRow";
import { InputLabel, SelectChangeEvent } from "@mui/material";
import InvalidMessage from "components/base/InvalidMessage";

interface Props extends FieldProps {
  label: string;
  options: any[];
  handleMutation: any;
  noEmptyOption: boolean;
  toggleActive?: boolean;
  isActive: boolean;
  readOnly: boolean;
  showComponentOnly?: boolean;
  placeholder?: string;
  width?: string;
  labelXs?: any;
}

const DEFAULT_PLACEHOLDER = "Please select";

const SelectField = ({
  field,
  form,
  label,
  options,
  handleMutation,
  isActive,
  toggleActive,
  readOnly,
  showComponentOnly,
  placeholder = "Please select",
  width,
  labelXs,
}: Props) => {
  const { errors, setFieldValue } = form;
  const { name = "", value = "" } = field;
  const { values }: { values: any } = useFormikContext();
  const fieldError = getIn(errors, name);
  const [disabled, setDisabled] = React.useState<boolean>(!isActive);
  const handleChange = ({ target: { value } }: SelectChangeEvent<any>) => {
    const defaultValue = value === DEFAULT_PLACEHOLDER ? "" : value;
    setFieldValue(name, defaultValue);
    handleMutation && handleMutation(name, defaultValue);
  };
  const clearTextInput = (name: string): void => {
    // clear out text field
    handleMutation && handleMutation(name, "");
    setFieldValue(name, "");
  };
  const handleDisable = (value: boolean) => {
    setDisabled(!value);
    handleMutation && handleMutation(name, "", value);
    // field has been disabled remove any set value
    if (!value) {
      setFieldValue(name, "");
      if (["Extent_Of_CT"].includes(name) && !value) {
        clearTextInput("Extent_Defined");
      }
      if (["Patient_Position"].includes(name) && !value) {
        clearTextInput("Position_Other");
      }
      if (["Bolus_Thickness"].includes(name) && !value) {
        clearTextInput("Bolus_Thickness_Custom");
      }
      if (["Dose_Frequency"].includes(name) && !value) {
        clearTextInput("Custom_Dose_Frequency");
      }
    }
  };

  // handle change on bolus field to activate sub fields
  React.useEffect(() => {
    if (["Bolus_Thickness", "Bolus_Frequency"].includes(name)) {
      setDisabled(values.Bolus !== true || isActive === false);
    }
    // eslint-disable-next-line
  }, [values]);

  const defaultValue = () => {
    const optionValues = options?.map((o) => o.value);
    if (!optionValues?.includes(value) || (!disabled && value === "")) {
      return "";
    }
    return value;
  };

  // // Avoid running the handleDisabled the first time the component loads this would reset
  // // current values.
  const firstUpdate = React.useRef(true);
  React.useEffect(() => {
    if (typeof isActive !== "undefined" && !firstUpdate.current)
      handleDisable(isActive);
    else firstUpdate.current = false;
    // eslint-disable-next-line
  }, [isActive]);

  const fieldErrorAsBool = Boolean(fieldError);

  return (
    <FormRow
      label={label}
      disabled={disabled}
      name={name}
      toggleActive={toggleActive}
      handleDisable={handleDisable}
      showComponentOnly={showComponentOnly}
      readOnly={readOnly}
      labelXs={labelXs}
      errorChildren={fieldError && <InvalidMessage text={fieldError} />}
    >
      <FormControl variant="outlined">
        <InputLabel shrink={false} id="select-label">
          {value.length ? "" : placeholder}
        </InputLabel>
        <Select
          labelId={`${name}-select-label-id`}
          id={`${name}-select-id`}
          value={defaultValue()}
          disabled={disabled || readOnly}
          onChange={handleChange}
          width={width}
          inputProps={{ "data-test-id": `${name}-select`, name: name }}
          error={fieldErrorAsBool}
          onClear={() => {
            setFieldValue(name, "");
            !fieldErrorAsBool && handleMutation && handleMutation(name, "");
          }}
        >
          {options?.map(
            (option: { label: string; value: any }, idx: number) => (
              <MenuItem key={idx} value={option.value}>
                {option.label}
              </MenuItem>
            )
          )}
        </Select>
      </FormControl>
    </FormRow>
  );
};

export default SelectField;
