import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";

// To be replaced by version in GCUI - Design team are still working how they want to standardise these elements so this should be deprecated soon
type ColorNames = "primary" | "destructive" | "information" | "neutral";

export const getBaseColor = (color: ColorNames): any => {
  if (["primary", "destructive", "information"].includes(color)) {
    return colors[color];
  } else {
    return colors["primary"];
  }
};

export interface PillProps {
  borderColor?: ColorNames;
  backgroundColor?: ColorNames;
  fontColor?: ColorNames;
  onClick?: () => void;
  width?: string;
  padding?: string;
}

const getFontColor = (props: PillProps) => {
  if (!props.fontColor) return colors.neutral.black;
  if (props.fontColor === "neutral") return colors.neutral.grey;
  return getBaseColor(props.fontColor).base;
};

const getBorderColor = (props: PillProps) => {
  if (!props.borderColor) return getBackgroundColor(props);
  if (props.borderColor === "neutral") return colors.neutral.lightestGrey;
  return getBaseColor(props.borderColor).base;
};

const getBackgroundColor = (props: PillProps) => {
  if (!props.backgroundColor) return "transparent";
  if (props.backgroundColor === "neutral") return colors.neutral.lightestGrey;
  return getBaseColor(props.backgroundColor).base;
};

export const Pill = styled.div<PillProps>`
  border-color: ${(props: PillProps) => getBorderColor(props)};
  background-color: ${(props: PillProps) => getBackgroundColor(props)};
  color: ${(props: PillProps) => getFontColor(props)};
  border-width: 2px;
  border-style: solid;
  border-radius: 100px;
  padding: ${(props: PillProps) => props.padding ?? "6px"};
  text-align: center;
  max-width: ${(props: PillProps) => props.width ?? "999px"};
  font-weight: 600;
  :hover {
    cursor: ${(props: PillProps) => (props.onClick ? "pointer" : "default")};
  }
`;
