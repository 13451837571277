import React from "react";
import { styled } from "@mui/system";
import { ToggleButton as MUIToggleButton } from "@mui/material";
import { colors } from "@lumonus/gc-ui";

const StyledToggleButton = styled(MUIToggleButton)`
  && {
    text-transform: none;
    color: ${(props) => (props.selected ? "white" : "black")};
    background-color: ${(props) =>
      props.selected ? colors.primary.base : "none"};
    border-color: ${(props: any) =>
      props.show_error ? colors.destructive.base : "none"};
    min-width: 140px;
    ${(props) =>
      props.disabled
        ? {
            backgroundColor: props.selected
              ? colors.neutral.grey
              : colors.neutral.lightestGrey,
            color: colors.neutral.darkGrey,
          }
        : ""};
  }
`;

const ToggleButton = (props: any) => (
  <StyledToggleButton
    selected={props.selected}
    disableRipple
    disabled={props.disabled}
    {...props}
  />
);

export default ToggleButton;
