import React, { useEffect, useState } from "react";
import FormularyTable from "components/Formulary/FormularyTable/FormularyTable";
import DrugModal from "components/Formulary/DrugModal/DrugModal";
import SearchField from "components/formFields/SearchField";
import { useLazyQuery, useQuery } from "@apollo/client";
import { Typography, Grid } from "@mui/material";
import Button from "components/base/Button";
import { GET_FORMULARY } from "graphql/FormularyQueries";
import SearchFilter from "components/SearchFilter/SearchFilter";
import styled from "styled-components";
import DrugContext, { DrugAction, DrugContextType } from "contexts/DrugContext";
import {
  DoseUnit,
  DrugInterface,
  DrugOrderActionType,
  DrugOrderInterface,
  emptyDrug,
  emptyDrugOrder,
} from "interfaces/formulary";
import DrugOrderModal from "components/Formulary/DrugOrderModal/DrugOrderModal";
import DrugOrderContext, {
  DrugOrderContextType,
} from "contexts/DrugOrderContext";
import { LOAD_FORMULARY_LIST_DATA } from "graphql/ListDataQueries";
import { SelectOptionsInterface } from "interfaces/common";
import { ROWS_PER_PAGE } from "components/TemplateSearchTables/tableUtils";

// TODO this should be shared
const StyledMenuItem = styled.div`
  padding: 6px 16px;
`;

const StyledContainer = styled.div`
  && {
    padding: 24px 8px 0 8px;
  }
`;

const formatListData = (listData: any) => {
  if (!listData?.formularyReferenceList) return {};

  const referenceList = listData.formularyReferenceList;
  const doseUnitList = {
    doseUnit: listData.doseUnitList.map((doseUnit: DoseUnit) => {
      return { label: doseUnit.name, value: doseUnit.id, data: doseUnit };
    }),
  };

  return referenceList.reduce((categoryOptions: any, categoryOption: any) => {
    const { category, data } = categoryOption;
    const { option } = data;
    categoryOptions[category.category] = !Object.prototype.hasOwnProperty.call(
      categoryOptions,
      category.category
    )
      ? [{ label: option, value: option }]
      : [
          ...categoryOptions[category.category],
          { label: option, value: option },
        ];
    return categoryOptions;
  }, doseUnitList);
};

const Formulary = () => {
  const [
    selectOptions,
    setSelectOptions,
  ] = useState<SelectOptionsInterface | null>(null);
  const { loading: listDataLoading } = useQuery(LOAD_FORMULARY_LIST_DATA, {
    fetchPolicy: "cache-first",
    onCompleted: (data) => {
      setSelectOptions(formatListData(data));
    },
  });

  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE[0]);

  // jankLoading is because the formulary table takes forever to render,
  // which results in "no results found" for a second, even if there are results
  const [jankLoading, setJankLoading] = useState(true);
  const [
    getDrugsQuery,
    { loading: formularyLoading, data: drugsData },
  ] = useLazyQuery(GET_FORMULARY, {
    fetchPolicy: "cache-first",
    onCompleted: (data) =>
      setJankLoading(data.formulary.drugs.drugs.length !== 0),
  });

  const [searchTerm, setSearchTerm] = useState<string>("");

  useEffect(() => {
    setJankLoading(true);
    setFilteredDrugs([]);
    getDrugsQuery({
      variables: {
        first: rowsPerPage,
        skip: page * rowsPerPage,
        searchTerm: searchTerm,
      },
    });
  }, [getDrugsQuery, page, rowsPerPage, searchTerm]);

  const [filteredDrugs, setFilteredDrugs] = useState<DrugInterface[]>([]);
  const [totalDrugCount, setTotalDrugCount] = useState(0);

  useEffect(() => {
    if (!drugsData) return;
    setTotalDrugCount(drugsData.formulary.drugs.totalCount);
    setFilteredDrugs(drugsData.formulary.drugs.drugs);
  }, [drugsData]);

  const [drug, setDrug] = useState<DrugInterface>(emptyDrug);
  const [modalIsVisible, setModalIsVisible] = useState(false);
  const [drugAction, setDrugAction] = useState(DrugAction.ADD);
  const drugInit: DrugContextType = {
    drug: drug,
    setDrug: setDrug,
    modalIsVisible: modalIsVisible,
    setModalIsVisible: setModalIsVisible,
    action: drugAction,
    setAction: setDrugAction,
  };

  const [drugOrder, setDrugOrder] = useState<DrugOrderInterface>(
    emptyDrugOrder
  );
  const [drugOrderModalIsVisible, setDrugOrderModalIsVisible] = useState(false);
  const [drugOrderAction, setDrugOrderAction] = useState(
    DrugOrderActionType.ADD
  );
  const drugOrderInit: DrugOrderContextType = {
    drugOrder: drugOrder,
    setDrugOrder: setDrugOrder,
    modalIsVisible: drugOrderModalIsVisible,
    action: drugOrderAction,
    setActionType: setDrugOrderAction,
    setModalIsVisible: setDrugOrderModalIsVisible,
  };

  return (
    <StyledContainer>
      <DrugContext.Provider value={drugInit}>
        <DrugOrderContext.Provider value={drugOrderInit}>
          <Typography variant="h4" gutterBottom>
            Formulary
          </Typography>
          <Grid
            container
            spacing={1}
            style={{ marginBottom: "5px", flexWrap: "unset" }}
            alignItems="center"
          >
            <Grid item>
              <SearchFilter>
                <StyledMenuItem></StyledMenuItem>
              </SearchFilter>
            </Grid>
            <Grid item>
              <SearchField
                dataTestId="formulary-search"
                placeholderText="Search drugs..."
                onEnter={(text) => {
                  setPage(0);
                  setSearchTerm(text);
                }}
              />
            </Grid>
            <Grid item container justifyContent="flex-end">
              <Button
                data-test-id="add-drug"
                onClick={() => {
                  setDrug(emptyDrug);
                  setDrugAction(DrugAction.ADD);
                  setModalIsVisible(true);
                }}
              >
                Add Drug
              </Button>
            </Grid>
          </Grid>
          <FormularyTable
            drugs={filteredDrugs}
            totalDrugCount={totalDrugCount}
            loading={listDataLoading || formularyLoading || jankLoading}
            page={page}
            rowsPerPage={rowsPerPage}
            onPageChange={setPage}
            onRowsPerPageChange={setRowsPerPage}
          />
          {selectOptions && (
            <>
              <DrugModal selectOptions={selectOptions}></DrugModal>
              <DrugOrderModal selectOptions={selectOptions}></DrugOrderModal>
            </>
          )}
        </DrugOrderContext.Provider>
      </DrugContext.Provider>
    </StyledContainer>
  );
};

export default Formulary;
