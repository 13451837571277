import React, { useContext, useState } from "react";
import {
  Container,
  TableCell,
  TableRow,
  TableBody,
  TableHead,
  TableSortLabel,
} from "@mui/material";

import { SmallTableCell, StyledTable } from "../../TableStyles";

import {
  directionalComparitor,
  prettyDate,
  sortArrayByComparitor,
  getDrugOrderDoseValue,
  formatUserName,
} from "shared/utils";
import {
  DoseBandInterface,
  DrugInterface,
  DrugOrderActionType,
  DrugOrderInterface,
  FormularyColumnInterface,
} from "interfaces/formulary";
import styled from "styled-components";
import DrugOrderContext from "contexts/DrugOrderContext";

import { ARCHIVE_DRUG_ORDER } from "graphql/FormularyQueries";
import { useMutation } from "@apollo/client";

import {
  StatusTrafficLight,
  ArchiveIcon,
  EditIcon,
  ColorFromStatus,
  CloneIcon,
} from "components/TemplateSearchTables/tableUtils";
import ArchiveModal from "../ArchiveModal/ArchiveModal";
import DrugContext from "contexts/DrugContext";

import { colors } from "@lumonus/gc-ui";

const DrugOrderTableHead = styled(TableHead)`
  height: 30px;
  && {
    .MuiTableCell-head {
      background-color: ${colors.primary.dark};
      color: white;
    }
    .MuiTableSortLabel-root:hover {
      background-color: ${colors.primary.dark};
      color: white;
    }
    .MuiTableSortLabel-active {
      background-color: ${colors.primary.dark};
      color: white;
    }
    .MuiTableSortLabel-icon {
      color: white !important;
    }
  }
`;

const StyledDrugOrderTable = styled(StyledTable)`
  table-layout: fixed;
`;

const StyledButtonRow = styled.div`
  display: flex;
  .MuiIconButton-root {
    padding: 3px;
  }
  justify-content: space-between;
`;

interface TableProps {
  drug: DrugInterface;
}

interface RowProps {
  drugOrder: DrugOrderInterface;
  drug: DrugInterface;
}

const columns: FormularyColumnInterface[] = [
  { label: "", attribute: "" },
  { label: "Name", attribute: "name" },
  // Keep this as reference for when we need them later
  // { label: 'Route', attribute: 'route' },
  // { label: 'Solution', attribute: 'solution' },
  // { label: 'Dose Basis Value', attribute: 'doseBasisValue' },
  // { label: 'Ordering Dose', attribute: 'orderingDose' },
  // { label: 'Range', attribute: 'range' },
  { label: "Type", attribute: "type" },
  { label: "Dose", attribute: "dose" },
  { label: "Route", attribute: "route" },
  { label: "Frequency", attribute: "frequency" },
  { label: "PRN", attribute: "prn" },
  { label: "Version", attribute: "version" },
  { label: "Last Modified", attribute: "createdAt" },
  { label: "Status", attribute: "status" },
  { label: "", attribute: "" },
];

const DrugOrderRow = ({ drugOrder, drug }: RowProps): JSX.Element => {
  // const drugName = `${drugOrder.name} (${drugOrder.orderingDose}${drugOrder.units})`;

  const drugOrderContext = useContext(DrugOrderContext);
  const drugContext = useContext(DrugContext);

  const startCloneDrugOrder = (
    drugOrder: DrugOrderInterface,
    drug: DrugInterface
  ) => {
    // Remapping the dose bands as they use a graphene.InputObjectType which freaks out when being sent __typename
    // TODO need a more elegant solution for this
    const newDrugOrder: DrugOrderInterface = Object.assign({}, drugOrder);
    newDrugOrder.id = undefined;
    newDrugOrder.name = `Clone - ${drugOrder.name}`;
    newDrugOrder.doseBands = newDrugOrder.doseBands.map(
      (b: DoseBandInterface) => ({
        minimum: b.minimum,
        maximum: b.maximum,
        bandedDose: b.bandedDose,
      })
    );

    drugContext.setDrug(drug);
    drugOrderContext.setDrugOrder({ ...newDrugOrder, drugId: drug.id });
    drugOrderContext.setActionType(DrugOrderActionType.CLONE);
    drugOrderContext.setModalIsVisible(true);
  };

  const startEditDrugOrder = (
    drugOrder: DrugOrderInterface,
    drug: DrugInterface
  ) => {
    // Remapping the dose bands as they use a graphene.InputObjectType which freaks out when being sent __typename
    // TODO need a more elegant solution for this
    const newDrugOrder: any = Object.assign({}, drugOrder);
    newDrugOrder.doseBands = newDrugOrder.doseBands.map(
      (b: DoseBandInterface) => ({
        id: b.id,
        minimum: b.minimum,
        maximum: b.maximum,
        bandedDose: b.bandedDose,
      })
    );

    drugContext.setDrug(drug);
    drugOrderContext.setDrugOrder(newDrugOrder);
    drugOrderContext.setActionType(DrugOrderActionType.EDIT);
    drugOrderContext.setModalIsVisible(true);
  };

  const [archiveDrugOrderMutation] = useMutation(ARCHIVE_DRUG_ORDER, {
    refetchQueries: ["Formulary"],
    awaitRefetchQueries: true,
    onCompleted: () => {
      setArchiveDialogOpen(false);
    },
    onError: () => {
      alert("Something went wrong");
    },
  });

  const [archiveDialogOpen, setArchiveDialogOpen] = useState(false);
  const handleArchiveDrugOrder = () =>
    archiveDrugOrderMutation({ variables: { id: drugOrder.id } });
  const dose = getDrugOrderDoseValue(drugOrder);
  const formattedName = formatUserName(drugOrder.updatedBy);
  return (
    <TableRow
      style={{ height: "56px" }}
      data-test-id={`drug-order-row-${drugOrder.id}`}
      hover
    >
      <SmallTableCell></SmallTableCell>
      <SmallTableCell data-test-id="drug-order-name">
        {drugOrder.name}
      </SmallTableCell>
      <SmallTableCell data-test-id="drug-order-type">
        {drugOrder.type}
      </SmallTableCell>
      <SmallTableCell data-test-id="drug-order-dose">{dose}</SmallTableCell>
      <SmallTableCell data-test-id="drug-order-route">
        {drugOrder.route}
      </SmallTableCell>
      <SmallTableCell data-test-id="drug-order-frequency">
        {drugOrder.frequency}
      </SmallTableCell>
      <SmallTableCell data-test-id="drug-order-prn">
        {drugOrder.prn ? "Yes" : "No"}
      </SmallTableCell>
      <SmallTableCell data-test-id="drug-order-version">
        {drugOrder.version}
      </SmallTableCell>
      <SmallTableCell>
        {formattedName && `${formattedName}, `}
        {prettyDate(drugOrder.editTime)}
      </SmallTableCell>
      <SmallTableCell data-test-id="drug-order-status">
        <StatusTrafficLight
          color={ColorFromStatus(drugOrder.isActive ? "approve" : "draft")}
        >
          {drugOrder.isActive ? "Active" : "Archived"}
        </StatusTrafficLight>
      </SmallTableCell>
      <SmallTableCell align="right">
        {drugOrder.isActive ? (
          <StyledButtonRow>
            <CloneIcon
              placement="bottom"
              onClick={() => startCloneDrugOrder(drugOrder, drug)}
              id={`clone-drug-order-${drugOrder.id}`}
            />
            <EditIcon
              placement="bottom"
              onClick={() => startEditDrugOrder(drugOrder, drug)}
              id={`edit-drug-order-${drugOrder.id}`}
            />
            <ArchiveIcon
              placement="bottom"
              onClick={() => setArchiveDialogOpen(true)}
              id={`archive-drug-order-${drugOrder.id}`}
            />
          </StyledButtonRow>
        ) : null}
      </SmallTableCell>
      <ArchiveModal
        onConfirm={handleArchiveDrugOrder}
        open={archiveDialogOpen}
        onClose={() => setArchiveDialogOpen(false)}
        title="Archive Drug Order"
        bodyText="Are you sure you would like to archive this drug order?"
        noteText="Current and historic patient careplans will not be affected."
      />
    </TableRow>
  );
};

const drugOrderHeader = (
  col: FormularyColumnInterface,
  index: number,
  orderBy: string,
  orderDirection: "asc" | "desc",
  onClickCallback: any
): JSX.Element => {
  const key = `drug-order-header-${index}`;
  const width = index === 0 ? "5%" : undefined;
  if (!col.label) return <TableCell width={width} key={key} />;
  return (
    <TableCell
      width={width}
      key={key}
      sortDirection={orderBy === col.attribute ? orderDirection : false}
    >
      <TableSortLabel
        active={orderBy === col.attribute}
        direction={orderDirection}
        onClick={() => onClickCallback(col.attribute)}
      >
        {col.label}
      </TableSortLabel>
    </TableCell>
  );
};

const DrugOrderTable = ({ drug }: TableProps) => {
  const [orderDirection, setOrderDirection] = useState<"asc" | "desc">("asc");
  const [orderBy2, setOrderBy] = useState<string>("");

  const handleSortingCallback = (attribute: string) => {
    const isAsc = orderBy2 === attribute && orderDirection === "asc";
    setOrderDirection(isAsc ? "desc" : "asc");
    setOrderBy(attribute);
  };

  return (
    <Container style={{ maxWidth: "100%", padding: "0" }}>
      <StyledDrugOrderTable size="small" data-test-id={"drug-order-table"}>
        <DrugOrderTableHead>
          <TableRow>
            {columns.map((header, index) =>
              drugOrderHeader(
                header,
                index,
                orderBy2,
                orderDirection,
                handleSortingCallback
              )
            )}
          </TableRow>
        </DrugOrderTableHead>
        <TableBody>
          {drug.drugOrders.length > 0 ? (
            sortArrayByComparitor(
              drug.drugOrders,
              directionalComparitor(orderDirection, orderBy2)
            ).map((drugOrder: DrugOrderInterface, index: number) => (
              <DrugOrderRow
                drugOrder={drugOrder}
                drug={drug}
                key={`drug-order-row-${index}`}
              ></DrugOrderRow>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length}>
                <p style={{ margin: "auto", paddingLeft: "8px" }}>
                  No drug orders available, please click{" "}
                  <b>&apos;Add Drug Order&apos;</b> to create one
                </p>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </StyledDrugOrderTable>
    </Container>
  );
};

export default DrugOrderTable;
