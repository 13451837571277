import styled from "styled-components";

export const ACTIVE = "Active";
export const INACTIVE = "Archived";

// Keys for local storage
export const TUMOUR_STREAMS = "tumourStreams";
export const ACTIVE_STATUS = "activeStates";
export const REGIONS = "regions";
export const SUBREGIONS = "subregions";

export const StyledMenuItem = styled.div`
  padding: 6px 16px;
`;

export const activeStatusToBoolean = (status: string) => {
  if (status === ACTIVE) return true;
  if (status === INACTIVE) return false;
  return null;
};
