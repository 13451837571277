import React, { Fragment } from "react";
import { useQuery } from "@apollo/client";
import { USER_QUERY } from "graphql/UserQueries";
import UserContext from "contexts/UserContext";

const ProtectedRoute = (props: React.PropsWithChildren) => {
  const { data, loading } = useQuery(USER_QUERY, {
    fetchPolicy: "cache-first",
  });
  if (loading) {
    return <div>Loading...</div>;
  }
  if (data && data.user && data.user.firstName) {
    return (
      <UserContext.Provider value={data.user}>
        <Fragment>{props.children}</Fragment>
      </UserContext.Provider>
    );
  }
  //if error or query didnt return reqd details
  else {
    return <h1>Not Authorized, Please login or use different credentials</h1>;
  }
};

export default ProtectedRoute;
