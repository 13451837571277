import React, { useEffect, useState } from "react";
import { Modal } from "@lumonus/gc-ui";

const ArchiveModal = ({
  onConfirm,
  onClose,
  open,
  title,
  bodyText,
  noteText,
}: any) => {
  const [busy, setBusy] = useState(false);
  useEffect(() => {
    if (open) {
      setBusy(false);
    }
  }, [open]);
  return (
    <Modal
      isOpen={open}
      dismissFunction={onClose}
      secondaryRightButton={{ buttonText: "Cancel", onClick: onClose }}
      primaryRightButton={{
        buttonText: "Archive",
        testId: "archive-modal-confirm-button",
        minWidth: "auto",
        onClick: () => {
          setBusy(true);
          onConfirm();
        },
        disabled: busy,
        type: busy ? "DISABLED" : "PRIMARY",
      }}
      headerText={title}
    >
      <p>{bodyText}</p>
      <p style={{ marginBottom: "-20px" }}>
        <b>{noteText}</b>
      </p>
    </Modal>
  );
};

export default ArchiveModal;
