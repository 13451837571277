import React from "react";
import styled from "styled-components";
import { Typography } from "@mui/material";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import FiberManualRecordIcon from "@mui/icons-material/FiberManualRecord";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { APPROVE, TEST, ARCHIVE, getNiceStatus } from "i18n/constants";
import { colors } from "@lumonus/gc-ui";

const getIcon = (status: string) => {
  if (status === APPROVE)
    return <CheckCircleIcon style={{ color: colors.success.base }} />;
  if (status === TEST)
    return <InfoIcon style={{ color: colors.information.base }} />;
  if (status === ARCHIVE)
    return <RemoveCircleIcon style={{ color: colors.destructive.base }} />;
  return <FiberManualRecordIcon style={{ color: colors.neutral.darkGrey }} />;
};

const StyledDiv = styled.div`
  display: flex;
  align-items: center;
`;

const StyledTypography = styled(Typography)<{ status: string }>`
  margin-left: 10px;
  color: ${(props: { status: string }) =>
    props.status === "archive"
      ? colors.neutral.darkGrey
      : colors.neutral.black};
`;

const Status = (props: any) => (
  <StyledDiv>
    {getIcon(props.status)}
    <StyledTypography
      data-test-id="template-status"
      variant="h5"
      status={props.status}
    >
      {getNiceStatus(props.status)}
    </StyledTypography>
  </StyledDiv>
);

export default Status;
