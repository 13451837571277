import React, { useEffect, useState } from "react";
import { colors, Modal } from "@lumonus/gc-ui";
import { useMutation, useQuery } from "@apollo/client";
import { Option } from "components/base/SearchDropdown";
import styled from "styled-components";
import { UPDATE_CYCLE_DRUG_ORDER } from "graphql/ProtocolQueries";
import DrugInfoPanel from "./DrugInfoPanel";
import { GET_DRUG_ORDER } from "graphql/FormularyQueries";
import { ProtocolDrugOrder, TemplateInterface } from "interfaces/templates";

interface Props {
  drugOrder: Option | null;
  open: boolean;
  editing: boolean;
  selectedCycle: number;
  setOpen: (value: boolean) => void;
  daysPerCycle: number;
  template: TemplateInterface;
  protocolDrugOrders: ProtocolDrugOrder[];
}

const DayButtonContainer = styled.div`
  display: flex;
  align-content: space-between;
  gap: 24px;
  flex-wrap: wrap;
`;

const LegacyModalBody = styled.div`
  color: ${colors.neutral.black};
`;

const StyledToggleButton = styled.div`
  && {
    display: flex;
    flex: none;
    justify-content: center;
    align-items: center;
    background-color: ${colors.neutral.lightestGrey};
    width: 67px;
    height: 32px;
    padding: 0;
    border-radius: 15px;
    cursor: pointer;
    :hover {
      background-color: ${colors.neutral.lightGrey};
    }
    &.selected {
      background-color: ${colors.primary.base};
      color: white;
    }
  }
`;

const StyledSubTitle = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
`;

const DrugModal = ({
  drugOrder,
  open,
  setOpen,
  editing,
  selectedCycle: cycle,
  daysPerCycle,
  template,
  protocolDrugOrders,
}: Props) => {
  const [selectedDays, setSelectedDays] = useState<number[]>(() => []);
  const [busy, setBusy] = useState(false);
  const { data: drugOrderInfo, loading } = useQuery(GET_DRUG_ORDER, {
    variables: { id: drugOrder?.id },
    skip: !drugOrder,
    fetchPolicy: "cache-and-network",
  });

  useEffect(() => {
    if (!template || !drugOrder) return;
    //reset selected days
    const matchingProtocolDrugOrder = protocolDrugOrders.filter(
      (protocolDrugOrder) => protocolDrugOrder.drugOrder.id === drugOrder.id
    );
    if (matchingProtocolDrugOrder.length !== 0) {
      // Always pick first. TODO: fix this to change to pick using protocol drug order id
      const selectedProtocolDrugOrder = matchingProtocolDrugOrder[0];
      setSelectedDays(
        template.doses
          .filter(
            (dose) =>
              dose.drugOrderInCycle.protocolDrugOrder.id ===
                selectedProtocolDrugOrder.id && dose.cycle === cycle
          )
          .map((dose) => dose.day)
      );
    } else {
      setSelectedDays([]);
    }
  }, [template, drugOrder, cycle]);

  const [submitMutation] = useMutation(UPDATE_CYCLE_DRUG_ORDER, {
    refetchQueries: ["Template", "drugOrderInCycle"],
    onCompleted: () => {
      setOpen(false);
      setBusy(false);
    },
    onError: () => {
      alert("Something went wrong");
      setBusy(false);
    },
  });

  const toggleDay = (day: number) => {
    if (selectedDays.includes(day)) {
      setSelectedDays(
        selectedDays.filter((d) => {
          return d !== day;
        })
      );
    } else {
      setSelectedDays([...selectedDays, day]);
    }
  };

  const submit = () => {
    setBusy(true);

    submitMutation({
      variables: {
        templateId: template.id,
        cycle: cycle,
        drugOrderId: drugOrder?.id,
        days: selectedDays,
      },
    });
  };

  const onClose = () => setOpen(false);
  if (!drugOrder) return null;

  if (loading) return <></>;

  return (
    <Modal
      isOpen={open}
      headerText={`Cycle ${cycle + 1} - ${editing ? "Update" : "Add"} ${
        drugOrder.label
      }`}
      maxWidth="717px"
      dismissFunction={onClose}
      secondaryRightButton={{ buttonText: "Cancel", onClick: onClose }}
      primaryRightButton={{
        buttonText: `${editing ? "Update" : "Add"} drug order`,
        minWidth: "auto",
        onClick: submit,
        disabled: busy,
        type: busy ? "DISABLED" : "PRIMARY",
      }}
    >
      <LegacyModalBody>
        <DrugInfoPanel {...drugOrderInfo.drugOrder} />
        <StyledSubTitle>Select Days:</StyledSubTitle>
        {!!template.negativeDays?.length && cycle === 0 && (
          <DayButtonContainer style={{ paddingBottom: "24px" }}>
            {[...template.negativeDays]
              .sort((a, b) => a - b)
              .map((n) => (
                <StyledToggleButton
                  key={n}
                  onClick={() => toggleDay(n)}
                  className={selectedDays.includes(n) ? "selected" : ""}
                  data-test-id={`day-button-${n}`}
                >
                  Day {n + 1}
                </StyledToggleButton>
              ))}
          </DayButtonContainer>
        )}
        <DayButtonContainer>
          {[...Array(daysPerCycle).keys()].map((dayIdx) => (
            <StyledToggleButton
              key={dayIdx}
              onClick={() => toggleDay(dayIdx)}
              data-test-id={`day-button-${dayIdx}`}
              className={selectedDays.includes(dayIdx) ? "selected" : ""}
            >
              Day {dayIdx + 1}
            </StyledToggleButton>
          ))}
        </DayButtonContainer>
      </LegacyModalBody>
    </Modal>
  );
};

export default DrugModal;
