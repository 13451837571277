import React, { useRef, useState, useContext, useEffect } from "react";
import { Grid } from "@mui/material";
import { Formik, Form, Field, FormikProps } from "formik";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
} from "@mui/material";
import { CREATE_DRUG, UPDATE_DRUG } from "graphql/FormularyQueries";
import { useMutation } from "@apollo/client";
import InputField from "components/formFields/InputField";
import { DRUG_CONTENT, CATEGORY_OPTIONS } from "i18n/constants";
import DrugContext, { DrugAction } from "contexts/DrugContext";
import { Button } from "@lumonus/gc-ui";
import { DrugInterface, emptyDrug } from "interfaces/formulary";
import SelectField from "components/formFields/SelectField";
import RangeField from "components/formFields/RangeField";
import ToggleButtonGroupField from "components/formFields/ToggleButtonGroupField";
import { SelectOptionsInterface } from "interfaces/common";
import * as Yup from "yup";
import ObjectSelectField from "components/formFields/ObjectSelectField";
import { scrollToErrorField } from "shared/utils";

const { fields } = DRUG_CONTENT;

interface Props {
  selectOptions: SelectOptionsInterface;
}

const invalidMessage = "This value is required";

const validationSchema = Yup.object().shape({
  genericName: Yup.string().required(invalidMessage),
  brandName: Yup.string().required(invalidMessage),
  route: Yup.string().required(invalidMessage),
  form: Yup.string().required(invalidMessage),
  doseUnit: Yup.object().shape({ id: Yup.string().required(invalidMessage) }),
  drugCategory: Yup.string().required(invalidMessage).nullable(),
});

const DrugModal = ({ selectOptions }: Props) => {
  const drugContext = useContext(DrugContext);
  const [formValues, setFormValues] = useState(emptyDrug);
  const [editing, setEditing] = useState(false);
  const [readOnly, setReadOnly] = useState(false);
  const [saving, setSaving] = useState(false);
  const [validateOnChange, setValidateOnChange] = useState(false);
  const [cloning, setCloning] = useState(false);

  const formRef = useRef<FormikProps<DrugInterface>>(null);

  const resetForm = (): void => {
    formRef?.current?.resetForm();
    setValidateOnChange(false);
  };

  // Handle state based on edit or add when modal is shown
  useEffect(() => {
    if (!drugContext.modalIsVisible) return;
    if (!drugContext.drug) return;

    let baseDrug = emptyDrug;
    if (drugContext.action === DrugAction.CLONE) {
      baseDrug = { ...drugContext.drug };
      baseDrug.id = undefined;
      baseDrug.route = "";
    }

    if (drugContext.action === DrugAction.EDIT) {
      setEditing(true);
      setReadOnly(true);
      setFormValues(drugContext.drug);
    } else {
      setCloning(drugContext.action === DrugAction.CLONE);
      setEditing(false);
      setReadOnly(false);
      setFormValues(baseDrug);
    }
    resetForm();
    // eslint-disable-next-line
  }, [drugContext.modalIsVisible]);

  // Refresh detection
  useEffect(() => {
    window.addEventListener("beforeunload", alertUser);
    return () => {
      window.removeEventListener("beforeunload", alertUser);
    };
    // eslint-disable-next-line
  }, []);

  const alertUser = (e: BeforeUnloadEvent) => {
    if (formRef?.current?.dirty) {
      e.preventDefault();
      e.returnValue = "aaa";
    }
  };

  const [addDrugMutation] = useMutation(CREATE_DRUG, {
    refetchQueries: ["Formulary"],
    onCompleted: () => {
      drugContext.setModalIsVisible(false);
      setSaving(false);
      resetForm();
    },
    onError: (err) => {
      if (err.message === "ROUTE_EXISTS") {
        formRef.current?.setFieldError(
          "route",
          "Drug with this route already exists"
        );
        scrollToErrorField(formRef.current?.errors);
      } else {
        alert("Something went wrong");
      }
      setSaving(false);
      setValidateOnChange(true);
    },
  });

  const [updateDrugMutation] = useMutation(UPDATE_DRUG, {
    refetchQueries: ["Formulary"],
  });

  const editDrug = () => {
    if (!drugContext.drug.id) {
      throw String("Attempting to edit with no drug selected");
    }

    if (saving) return;
    setSaving(true);
    const values = formRef.current?.values;
    const roundToNearest = values?.doseUnit.isCalculated
      ? values?.roundToNearest
      : null;
    updateDrugMutation({
      variables: { ...values, doseUnitId: values?.doseUnit.id, roundToNearest },
    });

    drugContext.setModalIsVisible(false);
    setSaving(false);
    resetForm();
  };

  const addDrug = () => {
    if (saving) return;
    setSaving(true);
    const values = formRef.current?.values;
    const roundToNearest = values?.doseUnit.isCalculated
      ? values?.roundToNearest
      : null;
    addDrugMutation({
      variables: {
        ...values,
        doseUnitId: values?.doseUnit.id,
        roundToNearest,
      },
    });
  };

  const ifValidThen = (onValid: any) => {
    formRef.current?.validateForm().then(() => {
      if (formRef.current?.isValid) {
        onValid();
      } else {
        setValidateOnChange(true);
        const errors = formRef.current?.errors;
        scrollToErrorField(errors);
      }
    });
  };

  const title = {
    [DrugAction.ADD]: "Add Drug",
    [DrugAction.EDIT]: "Modify Drug",
    [DrugAction.CLONE]: `Clone Drug - ${drugContext.drug.genericName}`,
  }[drugContext.action];

  return (
    <Dialog
      open={drugContext.modalIsVisible}
      onClose={() => drugContext.setModalIsVisible(false)}
      scroll="paper"
      data-test-id="add-drug-modal"
      fullWidth
      maxWidth="md"
      disableEscapeKeyDown={!editing || !readOnly}
    >
      <DialogTitle style={{ fontWeight: "bold" }}>
        <span>{title}</span>
      </DialogTitle>

      <DialogContent>
        <Formik
          initialValues={formValues}
          innerRef={formRef}
          onSubmit={() => {}}
          enableReinitialize
          validateOnChange={validateOnChange}
          validateOnBlur={false}
          validationSchema={validationSchema}
        >
          {(formikProps) => (
            <Form>
              <Grid container>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="generic-name"
                    name="genericName"
                    label={fields.genericName}
                    multiline
                    isActive={!editing && !cloning}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="brand-name"
                    name="brandName"
                    label={fields.brandName}
                    multiline
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={SelectField}
                    id="route"
                    name="route"
                    label={fields.route}
                    options={selectOptions.route}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={SelectField}
                    id="form"
                    name="form"
                    label={fields.form}
                    options={selectOptions.form}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={ObjectSelectField}
                    id="units"
                    name="doseUnit.id"
                    label={fields.units}
                    options={selectOptions.doseUnit}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={ToggleButtonGroupField}
                    id="category-id"
                    name="drugCategory"
                    label={fields.drugCategory}
                    options={CATEGORY_OPTIONS}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="concentration"
                    name="concentration"
                    label={fields.concentration}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={SelectField}
                    id="solution"
                    name="solution"
                    label={fields.solution}
                    options={selectOptions.solution}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="strength"
                    name="strength"
                    label={fields.strength}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                {formikProps.values.doseUnit.isCalculated && (
                  <Grid item xs={12}>
                    <Field
                      component={InputField}
                      id="round-to-nearest"
                      name="roundToNearest"
                      label={fields.roundToNearest}
                      type={"number"}
                      isActive={!readOnly}
                      labelXs={3}
                    />
                  </Grid>
                )}
                <Grid item xs={12}>
                  <RangeField
                    id="duration"
                    minField="minimumDuration"
                    maxField="maximumDuration"
                    isRangeField="durationIsRange"
                    unitOptions={selectOptions.durationUnits}
                    unitsField="durationUnits"
                    label={fields.duration}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    multiline
                    rows={3}
                    id="administration-instructions"
                    name="administrationInstructions"
                    label={fields.administrationInstructions}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    multiline
                    rows={3}
                    id="patient-instructions"
                    name="patientInstructions"
                    label={fields.patientInstructions}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="single-dose-limit"
                    name="singleDoseLimit"
                    label={fields.singleDoseLimit}
                    type={"number"}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Field
                    component={InputField}
                    id="lifetime-dose-limit"
                    name="lifetimeDoseLimit"
                    label={fields.lifetimeDoseLimit}
                    type={"number"}
                    isActive={!readOnly}
                    labelXs={3}
                  />
                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
      </DialogContent>

      <DialogActions>
        <Button
          title="Cancel"
          mode="outlined"
          data-test-id="btn-drug-dialog-cancel"
          onClick={() => {
            resetForm();
            drugContext.setModalIsVisible(false);
          }}
        />

        {!editing && (
          <Button
            size="medium"
            title="Add Drug"
            solidgreen="true"
            data-test-id="btn-drug-dialog-add"
            onClick={() => ifValidThen(addDrug)}
            variant="contained"
          />
        )}

        {!readOnly && editing && (
          <Button
            title="Update"
            solidgreen="true"
            data-test-id="btn-drug-dialog-update"
            onClick={() => ifValidThen(editDrug)}
            variant="contained"
          />
        )}

        {readOnly && editing && (
          <Button
            title="Edit"
            solidgreen="true"
            data-test-id="btn-drug-dialog-edit"
            onClick={() => setReadOnly(false)}
            variant="contained"
          />
        )}
      </DialogActions>
    </Dialog>
  );
};

export default DrugModal;
