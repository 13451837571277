import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import { ThemeProvider, Theme, StyledEngineProvider } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import theme from "./components/themes/theme";

import styled from "styled-components";
import Home from "pages/Home";
import CareplanCreatorFormApollo from "pages/CareplanCreatorFormApollo";
import CareplanSearch from "pages/search/CareplanSearch";
import ProtocolSearch from "pages/search/ProtocolSearch";
import Formulary from "pages/Formulary";
import BulkEdit from "pages/bulkEdit/BulkEdit";
import Template from "pages/Template";
import TopNav from "./components/base/TopNav";
import ProtectedRoute from "./components/ProtectedRoute";

const PageContainer = styled.div`
  overflow-y: unset;
  flex-wrap: wrap;
`;

const RouteContainer = styled.div`
  height: calc(100vh - 64px);
  width: 100%;
`;

const PageMessage = styled.h1`
  margin-top: 70px;
`;

const ValidUserComponent = () => (
  <PageContainer>
    <TopNav />
    <RouteContainer>
      <Switch>
        <Route exact path="/home" component={Home}></Route>
        <Route exact path="/protocol_search" component={ProtocolSearch} />
        <Route exact path="/formulary" component={Formulary} />
        <Route exact key="search" path="/search" component={CareplanSearch} />
        <Route exact key="review" path="/review" component={CareplanSearch} />
        <Route
          exact
          key="review"
          path="/review/sim"
          component={CareplanSearch}
        />
        <Route exact path="/template/:templateId" component={Template} />
        <Route
          path="/bulk_edit/:templateId/:form?/:groupId?"
          component={BulkEdit}
        />
        <Route
          path="/template/:templateId/:form?/:pageId?"
          component={CareplanCreatorFormApollo}
        />
        <Redirect from="/" to="/home" />
      </Switch>
    </RouteContainer>
  </PageContainer>
);

const App = (): JSX.Element => (
  <StyledEngineProvider injectFirst>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        <Route
          exact
          path="/notauthorized"
          component={() => (
            <div>
              <TopNav />
              <PageMessage>Not Authorized for this application</PageMessage>
            </div>
          )}
        />
        <Route
          exact
          path="/logout"
          component={() => (
            <div>
              <TopNav />
              <PageMessage>User logged out</PageMessage>
            </div>
          )}
        />
        <ProtectedRoute>
          <ValidUserComponent />
        </ProtectedRoute>
      </Switch>
    </ThemeProvider>
  </StyledEngineProvider>
);

export default App;
