import React from "react";
import styled from "styled-components";
import {
  Select as MUISelect,
  SelectProps,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { Cancel } from "@mui/icons-material";
import { colors } from "@lumonus/gc-ui";

interface Props extends SelectProps {
  width?: string;
  hasClearButton?: boolean;
  onClear?: any;
}

const TheSelect = ({
  hasClearButton = true,
  onClear,
  ...otherProps
}: Props) => (
  <MUISelect
    {...otherProps}
    endAdornment={
      otherProps.value !== "" &&
      hasClearButton &&
      !otherProps.disabled && (
        <InputAdornment position="end">
          <IconButton
            data-test-id={`clear-${otherProps.id}`}
            size="small"
            onClick={onClear}
          >
            <Cancel />
          </IconButton>
        </InputAdornment>
      )
    }
  />
);

const Select = styled(TheSelect)<Props>`
  border: ${colors.primary.base};
  width: ${(props) => props.width || "400px"};
  background-color: ${(props) =>
    props.disabled ? colors.neutral.lightestGrey : "inherit"};
  .MuiSelect-icon {
    color: ${(props) =>
      props.disabled ? colors.neutral.lightestGrey : colors.primary.base};
  }
  .MuiIconButton-root {
    outline: 0;
    color: ${colors.neutral.grey};
  }
  .MuiInputAdornment-root {
    padding-right: 15px;
  }
`;

export default Select;
