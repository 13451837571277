import React, { useState } from "react";
import styled from "styled-components";
import { Toolbar } from "@mui/material";
import { Link } from "react-router-dom";
import { colors, Button } from "@lumonus/gc-ui";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from "@mui/material";
import { useHistory } from "react-router-dom";

const StyledToolbar = styled(Toolbar)`
  width: 100%;
  background-color: white;
  border-top: 2px solid ${colors.primary.base};
  display: flex;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
`;
const Divider = styled.div`
  width: 10px;
  height: auto;
  display: inline-block;
`;

interface Props {
  backUrl: string;
  nextUrl: string;
  disableNext?: boolean;
  disableBack?: boolean;
  nextLabel?: string;
  backLabel?: string;
  nextFunction?: any;
  backFunction?: any;
}

const BottomNav = (props: Props): any => {
  const {
    backUrl,
    nextUrl,
    disableNext,
    disableBack,
    backLabel,
    nextLabel,
    nextFunction,
    backFunction,
  } = props;

  const bulkEditActive = window.location.pathname.startsWith("/bulk_edit/");

  const [discardModalOpen, setDiscardModalOpen] = useState(false);

  const disableLinkEvent = (e: any) => {
    e.preventDefault();
  };

  const BulkEditDiscardModal = () => {
    const history = useHistory();
    const discard = () => {
      sessionStorage.removeItem("bulkEditActive");
      sessionStorage.removeItem("bulkEditList");
      history.push("/review");
    };

    return (
      <Dialog
        open={discardModalOpen}
        onClose={() => setDiscardModalOpen(false)}
      >
        <DialogTitle>Discard Bulk Edit?</DialogTitle>
        <DialogContent>
          <>
            <p>
              You&apos;ll lose all changes and we can&apos;t recover them once
              you discard it
            </p>
            <p>Are you sure you want to discard bulk edit?</p>
          </>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setDiscardModalOpen(false)}
            mode="outlined"
            data-test-id="cancel-button"
          >
            Cancel
          </Button>

          <Button
            onClick={discard}
            color="destructive"
            data-test-id="discard-button"
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  return (
    <>
      <BulkEditDiscardModal />

      <StyledToolbar>
        {bulkEditActive && (
          <Button
            onClick={() => {
              setDiscardModalOpen(true);
            }}
            mode="outlined"
            color="primary"
            data-test-id="btn-discard-bulk-edit"
          >
            Discard Bulk Edit
          </Button>
        )}
        <div
          style={{ display: "flex", flexDirection: "row", marginLeft: "auto" }}
        >
          {backFunction ? (
            <Button
              onClick={backFunction}
              mode="outlined"
              color="primary"
              disabled={disableBack}
              data-test-id="btn-back"
            >
              {backLabel || "Back"}
            </Button>
          ) : (
            <StyledLink
              onClick={(e: any) => {
                disableBack && disableLinkEvent(e);
              }}
              to={backUrl}
            >
              <Button
                mode="outlined"
                disabled={disableBack}
                data-test-id="btn-back"
              >
                {backLabel || "Back"}
              </Button>
            </StyledLink>
          )}
          <Divider />
          {nextFunction ? (
            <Button
              onClick={nextFunction}
              mode="contained"
              color="primary"
              disabled={disableNext}
              data-test-id="btn-next"
            >
              {nextLabel || "Continue"}
            </Button>
          ) : (
            <StyledLink
              onClick={(e: any) => {
                disableNext && disableLinkEvent(e);
              }}
              to={nextUrl}
            >
              <Button
                mode="contained"
                disabled={disableNext}
                data-test-id="btn-next"
              >
                {nextLabel || "Continue"}
              </Button>
            </StyledLink>
          )}
        </div>
      </StyledToolbar>
    </>
  );
};

export default BottomNav;
