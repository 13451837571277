import {
  TableHead,
  Table,
  TableCell,
  TableContainer,
  Paper,
  TableSortLabel,
} from "@mui/material";

import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";

export const StyledPaper = styled(Paper)`
  && {
    width: 100%;
  }
`;

export const StyledTableHead = styled(TableHead)`
  && {
    background-color: ${colors.neutral.lightestGrey};
  }
`;

export const StyledTableSortLabel = styled(TableSortLabel)`
  && {
    font-weight: bold;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  && {
    max-height: calc(100vh - 285px);
    height: calc(100vh - 285px);
  }
`;

export const StyledTable = styled(Table)`
  && {
    max-height: 100%;
  }
`;

export const Dot = styled.span`
  background-color: ${colors.destructive.base};
  height: 16px;
  width: 16px;
  min-height: 16px;
  min-width: 16px;
  border-radius: 50%;
  margin-right: 4px;
`;

export const StatusContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

export const StyledTableCell = styled(TableCell)`
&& {
  padding-top: 6px;
  padding-bottom: 6px;
}}
`;

export const MiniTableCell = styled(StyledTableCell)`
&& {
  width: 4vw;
  max-width: 4vw;
  min-width: 4vw;
}}
`;

export const SmallTableCell = styled(StyledTableCell)`
&& {
  width: 6vw;
  max-width: 6vw;
  min-width: 6vw;
}}
`;

export const MediumTableCell = styled(StyledTableCell)`
&& {
  width: 10vw;
  max-width: 10vw;
  min-width: 10vw;
}}
`;

export const LargeTableCell = styled(StyledTableCell)`
&& {
  width: 20vw;
  max-width: 20vw;
  min-width: 20vw;
}}
`;
