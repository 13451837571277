import React from "react";
import Button from "@mui/material/Button";
import Menu, { MenuProps } from "@mui/material/Menu";
import FilterList from "@mui/icons-material/FilterList";
import { colors } from "@lumonus/gc-ui";
import { styled } from "@mui/material/styles";

const StyledButton = styled(Button)`
&& {
    text-transform: none;
    color: ${colors.neutral.black};
    border-color: ${colors.neutral.darkGrey};
    min-width: 100px;
    border-radius: 2em;
    min-height: 2em;
    font-size: 16px;
    border-width: 2px;
    }
  }}
`;

const StyledMenu = (props: MenuProps) => (
  <Menu
    sx={{
      paper: {
        border: `1px solid ${colors.neutral.lightGrey}`,
      },
    }}
    elevation={0}
    hideBackdrop={false}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
);

interface Props {
  children: any;
}

const SearchFilter = (props: Props): any => {
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div>
      <StyledButton
        aria-controls="customized-menu"
        aria-haspopup="true"
        startIcon={<FilterList />}
        data-test-id={"search-filters"}
        onClick={handleClick}
      >
        Filter
      </StyledButton>
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {props.children}
      </StyledMenu>
    </div>
  );
};

export default SearchFilter;
