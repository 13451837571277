import { gql } from "@apollo/client";

export const OAR_FRAGMENT = gql`
  fragment oarFragment on OarType {
    siteGroupId
    templateId
    organ
    maxValue
    maxValueUnit
    meanValueUnit
    meanValue
    alara
    volumeConstraintData {
      firstValue
      firstValueUnit
      secondValue
      secondValueUnit
      operator
    }
    dConstraintData {
      dosetoPrefixOperator
      firstValue
      firstValueUnit
      secondValue
      secondValueUnit
      operator
    }
  }
`;

export const GET_ORGAN_LIST = gql`
  query organList($siteGroupId: ID!) {
    organList(siteGroupId: $siteGroupId) {
      id
      organ
    }
  }
`;

export const GET_OAR_TEMPLATE = gql`
  ${OAR_FRAGMENT}
  query oarTemplate($groupId: ID!) {
    oarTemplate(groupId: $groupId) {
      ...oarFragment
    }
  }
`;

export const ADD_OAR = gql`
  mutation createOar(
    $siteGroupId: ID!
    $alara: Boolean
    $organ: String
    $volumeConstraintData: [OarConstraintInputType]
    $dConstraintData: [OarConstraintInputType]
    $maxValue: [Float]
    $maxValueUnit: String
    $meanValue: [Float]
    $meanValueUnit: String
  ) {
    createOar(
      siteGroupId: $siteGroupId
      alara: $alara
      organ: $organ
      maxValue: $maxValue
      maxValueUnit: $maxValueUnit
      meanValue: $meanValue
      meanValueUnit: $meanValueUnit
      volumeConstraintData: $volumeConstraintData
      dConstraintData: $dConstraintData
    ) {
      oar {
        siteGroupId
        organ
        maxValue
        maxValueUnit
        meanValue
        alara
        volumeConstraintData {
          firstValue
          firstValueUnit
          secondValue
          secondValueUnit
          operator
        }
        dConstraintData {
          dosetoPrefixOperator
          firstValue
          firstValueUnit
          secondValue
          secondValueUnit
          operator
        }
      }
    }
  }
`;

export const UPDATE_OAR = gql`
  mutation updateOar(
    $siteGroupId: ID!
    $index: ID!
    $alara: Boolean
    $organ: String
    $volumeConstraintData: [OarConstraintInputType]
    $dConstraintData: [OarConstraintInputType]
    $maxValue: [Float]
    $maxValueUnit: String
    $meanValue: [Float]
    $meanValueUnit: String
  ) {
    updateOar(
      siteGroupId: $siteGroupId
      index: $index
      alara: $alara
      organ: $organ
      maxValue: $maxValue
      maxValueUnit: $maxValueUnit
      meanValue: $meanValue
      meanValueUnit: $meanValueUnit
      volumeConstraintData: $volumeConstraintData
      dConstraintData: $dConstraintData
    ) {
      oar {
        siteGroupId
        organ
        maxValue
        alara
        maxValueUnit
        meanValue
        meanValueUnit
        volumeConstraintData {
          firstValue
          firstValueUnit
          secondValue
          secondValueUnit
          operator
        }
        dConstraintData {
          dosetoPrefixOperator
          firstValue
          firstValueUnit
          secondValue
          secondValueUnit
          operator
        }
      }
    }
  }
`;

export const DELETE_OAR = gql`
  mutation deleteOar($siteGroupId: ID!, $index: ID!) {
    deleteOar(siteGroupId: $siteGroupId, index: $index) {
      ok
    }
  }
`;
