import React from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "@mui/material";
import { colors } from "@lumonus/gc-ui";
import DebouncedButton from "components/base/DebouncedButton";

interface Props extends ButtonProps {
  customcolor?: string;
}

const BorderColor = (props: Props) => {
  if (props.disabled) return "default";
  if (props.customcolor) return props.customcolor;
  return colors.neutral.darkGrey;
};

const StyledButton = styled(Button)<Props>`
  && {
    text-transform: none;
    min-width: 200px;
    border: solid;
    border-radius: 2em;
    border-color: ${(props: Props) => BorderColor(props)};
    min-height: 2em;
    font-size: 16px;
    border-width: 2px;
    margin-top: 5px;
    margin-bottom: 5px;
    color: ${(props: Props) => BorderColor(props)};
  }
  & svg {
    color: ${(props: Props) => BorderColor(props)};
  }
`;

const LandingPageButton = (props: any) => (
  <DebouncedButton Component={StyledButton} variant="outlined" {...props} />
);

export default LandingPageButton;
