import React, { Fragment, useEffect } from "react";
import { FieldProps } from "formik";
import Input from "components/base/Input";
import FormRow from "components/base/FormRow";
import Divider from "@mui/material/Divider";
import { tvName } from "i18n/constants";

interface Props extends FieldProps {
  label: string;
  handleMutation: any;
  type: string;
  multiline: boolean;
  length: number;
  id: any;
  isActive: boolean;
}

const TvInputField = ({
  field,
  form,
  label,
  handleMutation,
  length,
  type = "text",
  multiline = false,
  id,
  isActive,
}: Props) => {
  const { onBlur, name, value } = field;
  const { setFieldValue } = form;
  const handleBlur = (event: React.ChangeEvent<any>, index: number) => {
    onBlur(event);
    const newValue = [
      ...value.slice(0, index),
      event.target.value,
      ...value.slice(index + 1),
    ];
    handleMutation(name, newValue);
  };

  const handleChange = (
    { target: { value: newVal } }: React.ChangeEvent<any>,
    index: number
  ) => {
    const newValue = [
      ...value.slice(0, index),
      newVal,
      ...value.slice(index + 1),
    ];
    setFieldValue(name, newValue);
  };

  // Trigger handleMutation when the user stops typing
  useEffect(() => {
    const mutateAfterNoInput = setTimeout(() => {
      if (isActive) handleMutation(name, value);
    }, 500);
    return () => clearTimeout(mutateAfterNoInput);
    // eslint-disable-next-line
  }, [value]);

  const isSib = length > 1;
  const names = tvName(length);
  return (
    <Fragment>
      {isSib && <Divider />}
      {isSib && (
        <FormRow label={label} name={name}>
          <div></div>
        </FormRow>
      )}
      {Array.from(Array(length)).map((_, index) => {
        const sibLabel = names[index] || "";
        return (
          <FormRow
            key={index}
            disabled={!isActive}
            label={isSib ? sibLabel : label}
            name={name}
          >
            <Input
              name={name}
              value={value[index] || ""}
              onChange={(e) => handleChange(e, index)}
              id={`input-${id}-${index}`}
              variant="outlined"
              onBlur={(e) => handleBlur(e, index)}
              size="medium"
              type={type}
              multiline={multiline}
              inputProps={{ "data-test-id": `input-${id}-${index}` }}
              disabled={!isActive}
            />
          </FormRow>
        );
      })}
    </Fragment>
  );
};

export default TvInputField;
