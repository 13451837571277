import React from "react";
import { Card, CardContent, CardProps } from "@mui/material";
import styled from "styled-components";

interface Props {
  children: any;
  heading: string;
  borderColor: string;
}

interface StyledCardProps extends CardProps {
  bordercolor: string;
}

const Scrollable = styled.div`
  max-height: 280px;
  overflow-y: auto;
`;

const StyledCard = styled(Card)<StyledCardProps>`
  max-width: 800px;
  margin-bottom: 24px;
  margin-left: 18px;
  border-top: 8px solid
    ${(props) => (props.bordercolor ? props.bordercolor : "")};
`;

const BulkEditCard = (props: Props) => {
  const { heading } = props;
  return (
    <StyledCard bordercolor={props.borderColor}>
      <CardContent>
        <h3 data-test-id={`heading-${heading}`}>{heading}</h3>
        <Scrollable>{props.children}</Scrollable>
      </CardContent>
    </StyledCard>
  );
};

export default BulkEditCard;
