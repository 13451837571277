import { gql } from "@apollo/client";
import { OAR_FRAGMENT } from "./OarQueries";

export const SEARCH_CAREPLAN_TEMPLATES = gql`
  ${OAR_FRAGMENT}
  query TemplateFilter(
    $searchTerm: String
    $tumourStreams: [String]
    $activeStatus: [Boolean]
    $regions: [String]
    $subregions: [String]
    $start: Int
    $limit: Int
    $isMo: Boolean!
    $isRo: Boolean!
    $isTemporary: Boolean!
    $includeTreatment: Boolean!
  ) {
    templateFilter(
      searchTerm: $searchTerm
      tumourStreams: $tumourStreams
      activeStatus: $activeStatus
      regions: $regions
      subregions: $subregions
      start: $start
      limit: $limit
      isMo: $isMo
      isRo: $isRo
      isTemporary: $isTemporary
      includeTreatment: $includeTreatment
    ) {
      totalLength
      templates {
        id
        name
        siteFilter
        techniqueFilter
        doseFilter
        numberOfCycles
        daysPerCycle
        evidenceId
        evidenceVersion
        isMo
        isRo
        continuous
        careplanTreatment @include(if: $includeTreatment) {
          id
          name
          oar {
            ...oarFragment
          }
          siteGroupRules {
            id
            isShown
            defaultValue
            field {
              id
              name
            }
          }
          siteTemplate {
            id
            site {
              name
            }
            ruleSite {
              id
              isShown
              defaultValue
              field {
                id
                name
              }
            }
          }
        }
        tumourStream {
          id
          name
        }
        tumourStreamName
        createdAt
        isActive
        status
        regions {
          id
          name
        }
        isNational
        subregions {
          id
          name
          region {
            id
            name
          }
        }
      }
    }
  }
`;

export const CHECK_DUPLICATE_NAME = gql`
  query nameSearch($name: String!, $templateId: ID!, $tumourStream: String) {
    nameSearch(
      name: $name
      templateId: $templateId
      tumourStream: $tumourStream
    )
  }
`;

export const SEARCH_PROTOCOL_TEMPLATES = gql`
  query TemplateFilter(
    $searchTerm: String
    $tumourStreams: [String]
    $activeStatus: [Boolean]
    $regions: [String]
    $subregions: [String]
    $start: Int
    $limit: Int
  ) {
    templateFilter(
      searchTerm: $searchTerm
      tumourStreams: $tumourStreams
      activeStatus: $activeStatus
      regions: $regions
      subregions: $subregions
      start: $start
      limit: $limit
    ) {
      totalLength
      templates {
        id
        name
        numberOfCycles
        daysPerCycle
        tumourStream {
          id
          name
        }
        createdAt
        isActive
        status
        regions {
          id
          name
        }
      }
    }
  }
`;
