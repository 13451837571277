import React, { useState } from "react";
import { Autocomplete as MUIAutocomplete } from "@mui/material";
import Input from "./Input";
import styled from "styled-components";
import { InputLabel } from "@mui/material";
import { ExpandMore } from "@mui/icons-material";
import { colors } from "@lumonus/gc-ui";

const StyledInput = styled(Input)`
  margin-top: 8px;
  background-color: white;
  width: 100%;
`;

const StyledInputLabel = styled(InputLabel)`
  font-weight: 700;
  color: black;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
`;

export interface Option {
  label: string;
  id: string;
}
export interface Props {
  value: string;
  label: string;
  options: Option[];
  onChange: (value: any, reason?: string) => any;
  onSelect: (value: Option | null) => any;
  onOpen: () => any;
  onClose: () => any;
  id: string;
}

const SearchBox = ({
  value,
  label,
  options,
  onChange,
  onSelect,
  onOpen,
  onClose,
  id,
}: Props) => {
  const [selected, setSelected] = useState<Option | null>(null);
  return (
    <MUIAutocomplete
      data-test-id={id}
      options={options}
      value={selected}
      inputValue={value}
      style={{ height: "fit-content" }}
      clearOnBlur={false}
      onChange={(_ev: any, value: Option | null) => {
        onSelect(value);
        setSelected(null);
      }}
      onInputChange={(_ev: any, value: string, reason: string) =>
        onChange(value, reason)
      }
      getOptionLabel={(option) => option.label}
      renderInput={(params) => (
        <>
          <StyledInputLabel>{label}</StyledInputLabel>
          <StyledInput
            {...params}
            variant="outlined"
            placeholder="Search e.g. cisplatin"
          />
        </>
      )}
      onClose={onClose}
      onOpen={onOpen}
      size="small"
      popupIcon={<ExpandMore style={{ color: colors.primary.base }} />}
      fullWidth
      noOptionsText="No results"
      openOnFocus
      blurOnSelect
    />
  );
};

export default SearchBox;
