import styled from "styled-components";
import { Checkbox as MUICheckbox } from "@mui/material";
import { colors } from "@lumonus/gc-ui";

const Checkbox = styled(MUICheckbox)`
  && {
    &.Mui-checked {
      color: ${(props) =>
        props.disabled
          ? colors.neutral.darkGrey
          : props.checked
          ? colors.primary.base
          : "white"};
    }
  }
`;

export default Checkbox;
