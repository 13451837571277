import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { Formik, Form, Field } from "formik";
import { useQuery, useMutation } from "@apollo/client";
import { Divider } from "@mui/material";
import Grid from "@mui/material/Grid";
import Checkbox from "components/base/Checkbox";
import Button from "components/base/Button";
import FormPage from "components/base/FormPage";
import InputField from "components/formFields/InputField";
import TvInputField from "components/formFields/TvInputField";
import OarTable from "components/oars/OarTable";
import OarModal from "components/oars/OarModal";

import { volumingContent, sortSites } from "i18n/constants";

import {
  GET_ALL_SITES_FOR_GROUP,
  UPDATE_TEMPLATE_SITE_FIELDS,
} from "../graphql/SiteQueries";
import {
  GET_SITE_GROUP_TEMPLATE,
  UPDATE_TEMPLATE_GROUP_FIELDS,
} from "../graphql/SiteGroupQueries";
import { GET_OAR_TEMPLATE } from "../graphql/OarQueries";
import { getSelectedForBulkEdit } from "shared/utils";

import AdditionalOARInfo from "components/AdditionalOARInfo";

interface Props {
  disableEditing: boolean;
  groupIds: any[];
}

interface ParamTypes {
  templateId: string;
  groupId: string;
}

const [volumingFields] = [volumingContent.fields];

export const volumingPageBack = (
  templateId: string,
  groupId: string,
  groupIds: any,
  lastSiteId: string
): string => {
  const siteIndex: number = groupIds.indexOf(groupId);
  const back =
    siteIndex === 0
      ? `/template/${templateId}/prescription/${lastSiteId}`
      : `/template/${templateId}/voluming/${groupIds[siteIndex - 1]}`;
  return back;
};

export const volumingPageNextText = (
  groupId: string,
  groupIds: any,
  showPeerReview: boolean
): string => {
  const siteIndex: number = groupIds.indexOf(groupId);
  if (showPeerReview) {
    return "Continue";
  }
  return siteIndex < groupIds.length - 1 ? "Continue" : "Template Page";
};

export const volumingPageNext = (
  templateId: string,
  groupId: string,
  groupIds: any,
  showPeerReview: boolean
): string => {
  const siteIndex: number = groupIds.indexOf(groupId);
  const next =
    siteIndex < groupIds.length - 1
      ? `/template/${templateId}/voluming/${groupIds[siteIndex + 1]}`
      : showPeerReview
      ? `/template/${templateId}/peerReview`
      : `/template/${templateId}`;
  return next;
};

const Voluming = ({ disableEditing, groupIds }: Props) => {
  const { groupId } = useParams<ParamTypes>();
  const siteIndex: number = groupIds.indexOf(groupId);
  const { heading, fields } = volumingContent;
  const inBulkEdit = window.location.pathname.includes("bulk_edit");
  const [isModalVisible, setIsModalVisible] = React.useState(false);

  const [updateTemplateSiteFields] = useMutation(UPDATE_TEMPLATE_SITE_FIELDS);
  const [updateTemplateGroupFields] = useMutation(
    UPDATE_TEMPLATE_GROUP_FIELDS,
    {
      refetchQueries: [
        { query: GET_SITE_GROUP_TEMPLATE, variables: { groupId } },
        { query: GET_OAR_TEMPLATE, variables: { groupId } },
      ],
    }
  );

  const { data: siteGroupData } = useQuery(GET_SITE_GROUP_TEMPLATE, {
    fetchPolicy: "cache-and-network",
    variables: { groupId },
  });

  const oarSelectedForBulkEdit = getSelectedForBulkEdit(
    siteGroupData?.siteGroupTemplate?.siteGroupRules,
    "OAR"
  );

  const siteGroupName = ` - Site Group ${siteIndex + 1}` || "";
  const { data: oarData, loading, error } = useQuery(GET_OAR_TEMPLATE, {
    variables: { groupId },
  });
  const { data } = useQuery(GET_ALL_SITES_FOR_GROUP, {
    fetchPolicy: "cache-and-network",
    variables: { groupId },
  });
  const allSiteData = sortSites(data?.siteGroupTemplate?.siteTemplate || []);

  // Determine if adding or editing OAR
  const [state, setState] = useState({ oarData: null, editOarIndex: -1 });
  useEffect(() => {
    if (state.editOarIndex !== -1 && oarData && oarData.oarTemplate) {
      setState({ ...state, oarData: oarData.oarTemplate[state.editOarIndex] });
    }
    // eslint-disable-next-line
  }, [data]);

  const volumingForm = (siteData: any, index: number) => {
    const siteId = siteData?.id;
    const ruleSite = siteData?.ruleSite || [];

    const handleMutation = (fieldName: string, value: any) => {
      const updateVariables = {
        siteId,
        fieldName,
        defaultValue: fieldName === "GTV" ? value : JSON.stringify(value),
      };
      updateTemplateSiteFields({ variables: updateVariables });
    };

    const defaultValues = ruleSite.reduce(
      (fieldValues: any, fieldDetails: any) => {
        const { field, defaultValue } = fieldDetails;
        const value = JSON.parse(defaultValue);
        fieldValues[field.name] =
          value === "true" || value === "false" ? JSON.parse(value) : value;
        return fieldValues;
      },
      {}
    );

    const doseValue =
      typeof defaultValues.Dose === "string"
        ? JSON.parse(defaultValues.Dose)
        : defaultValues.Dose;
    const doseLength = doseValue?.length || 1;

    Object.keys(volumingFields)
      .filter(
        (fieldName: any) =>
          !Object.prototype.hasOwnProperty.call(defaultValues, fieldName)
      )
      .map(
        (fieldName: any) =>
          (defaultValues[fieldName] = ["CTV", "PTV"].includes(fieldName)
            ? [""]
            : "")
      );

    return (
      <Formik
        key={siteId}
        enableReinitialize
        initialValues={defaultValues}
        onSubmit={() => {}}
      >
        <Form>
          <FormPage
            heading={index === 0 ? `${heading} ${siteGroupName}` : ""}
            siteHeading={siteData?.site?.name}
          >
            <Field
              component={InputField}
              id={`gtv-id-${index}`}
              name="GTV"
              label={fields.GTV}
              handleMutation={handleMutation}
              isActive={!disableEditing}
              multiline={true}
            />
            <Field
              component={TvInputField}
              id={`ctv-id-${index}`}
              name="CTV"
              label={fields.CTV}
              handleMutation={handleMutation}
              length={doseLength}
              isActive={!disableEditing}
              multiline={true}
            />
            <Field
              component={TvInputField}
              id={`ptv-id-${index}`}
              name="PTV"
              label={fields.PTV}
              handleMutation={handleMutation}
              length={doseLength}
              isActive={!disableEditing}
              multiline={true}
            />
            <Divider />
          </FormPage>
        </Form>
      </Formik>
    );
  };

  const handleMutation = (
    fieldName: string,
    value: any,
    isActive?: boolean
  ) => {
    let variables = { groupId, fieldName, defaultValue: JSON.stringify(value) };
    if (inBulkEdit) {
      variables = Object.assign({ isSelectedForBulkEdit: isActive }, variables);
    } else if (typeof isActive === "boolean") {
      variables = Object.assign({ isShown: isActive, isActive }, variables);
    }
    updateTemplateGroupFields({ variables });
  };

  const organsInUse = oarData?.oarTemplate?.map((oar: any) => oar.organ) || [];

  const addOar = () => {
    setState({ oarData: null, editOarIndex: -1 });
    setIsModalVisible(true);
  };

  const editOar = (index: number) => {
    setState({ oarData: oarData.oarTemplate[index], editOarIndex: index });
    setIsModalVisible(true);
  };

  return (
    <>
      {!data && loading && <div>Loading ...</div>}
      {error && <div>Error, please contact support</div>}
      {allSiteData &&
        allSiteData.map((siteData: any, index: number) =>
          siteData ? volumingForm(siteData, index) : null
        )}
      <OarModal
        setIsVisible={setIsModalVisible}
        isVisible={isModalVisible}
        index={state.editOarIndex}
        oarData={state.oarData}
        siteGroupId={groupId}
        organsInUse={organsInUse}
      />
      <h2>Organs at Risk</h2>
      {inBulkEdit && (
        <p>
          Please <Checkbox checked={true}></Checkbox> select the field(s) to
          enable editing
        </p>
      )}

      <Grid container>
        {inBulkEdit && (
          <Grid item xs={"auto"} sx={{ paddingTop: "16px" }}>
            <Checkbox
              data-test-id="OAR-bulk-edit-checkbox"
              checked={oarSelectedForBulkEdit}
              onChange={(e: React.ChangeEvent<any>) =>
                handleMutation("OAR", [], e.target.checked)
              }
            ></Checkbox>
          </Grid>
        )}
        <Grid item xs={8}>
          <OarTable
            oars={oarData?.oarTemplate || []}
            editOar={editOar}
            siteGroupId={groupId}
            isActive={
              !disableEditing || (inBulkEdit && !oarSelectedForBulkEdit)
            }
          ></OarTable>

          {inBulkEdit && (
            <p style={{ maxWidth: "800px" }}>
              Note: For bulk edits the previous organ at risk constraints will
              be cleared. When editing please ensure to add in the complete set
              of constraints.
            </p>
          )}

          <Button
            disabled={disableEditing || (inBulkEdit && !oarSelectedForBulkEdit)}
            id="addOar"
            onClick={() => addOar()}
          >
            Add OAR
          </Button>
        </Grid>
      </Grid>
      <AdditionalOARInfo disableEditing={disableEditing} />
    </>
  );
};

export default Voluming;
