/* eslint-disable  */
import React, { useState } from "react";
import styled from "styled-components";
import { useFormikContext, FieldArray, FieldProps } from "formik";
import Button from "components/base/Button";
import { TextField, IconButton } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import Autocomplete from "@mui/material/Autocomplete";
import ErrorMessage from "components/base/ErrorMessage";
import { useMutation } from "@apollo/client";
import { GET_CAREPLAN_TEMPLATE } from "graphql/TemplateQueries";
import {
  CREATE_SITE_TEMPLATE,
  UPDATE_SITE_TEMPLATE,
  DELETE_SITE_TEMPLATE,
  UPDATE_SITE_TEMPLATE_NAME,
} from "graphql/SiteQueries";
import {
  ADD_SITE_GROUP_TEMPLATE,
  DELETE_SITE_GROUP_TEMPLATE,
} from "graphql/SiteGroupQueries";

import TreatmentSitesTable from "./TreatmentSitesTable";
import DebouncedButton from "components/base/DebouncedButton";
import { colors } from "@lumonus/gc-ui";

type TreatmentSiteType = {
  site: string;
  siteGroupSites: string;
};
interface Props extends FieldProps<any, TreatmentSiteType> {
  label: string;
  autocompleteLabel?: string;
  buttonLabel: string;
  options: any[];
  templateId: any;
  template: any;
  isActive: boolean;
}

const SiteWrapperDiv = styled.div`
  max-width: 700px;
  border: 1px solid ${colors.neutral.darkGrey};
  border-radius: 8px;
  margin: 30px 0px;
  padding: 12px;
`;

const StyledSiteSelectDiv = styled.div`
  margin: 12px 0px;
  width: 50%;
`;

const FlexStartDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const StyledGroupNameWrapper = styled.div`
  display: flex;
  margin-right: 15px;
  font-weight: bold;
`;

const TreatmentSites = ({
  field,
  label,
  autocompleteLabel,
  options,
  templateId,
  template,
  form,
  isActive,
}: Props) => {
  const [createSiteTemplate] = useMutation(CREATE_SITE_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
    awaitRefetchQueries: true,
  });

  const [addSiteGroupTemplate] = useMutation(ADD_SITE_GROUP_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
    awaitRefetchQueries: true,
  });

  const [updateSiteTemplate] = useMutation(UPDATE_SITE_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteSiteTemplate] = useMutation(DELETE_SITE_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
    awaitRefetchQueries: true,
  });

  const [deleteSiteGroupTemplate] = useMutation(DELETE_SITE_GROUP_TEMPLATE, {
    refetchQueries: [
      { query: GET_CAREPLAN_TEMPLATE, variables: { templateId } },
    ],
    awaitRefetchQueries: true,
  });

  const [updateSiteTemplateName] = useMutation(UPDATE_SITE_TEMPLATE_NAME);

  const { values }: { values: any } = useFormikContext();
  const [siteName, setSiteName] = useState("");
  const { errors } = form;
  const { name } = field;
  const siteGroups = template.careplanTreatment;
  const emptySiteGroup = siteGroups.some(
    (siteGroup: any) => siteGroup.siteTemplate.length === 0
  );

  const handleUpdateSiteTemplate = (siteId: string, value: any) => {
    const newSiteGroupId = siteGroups.find(
      (newSiteGroup: any) => newSiteGroup.id === value
    )?.id;
    updateSiteTemplate({
      variables: { siteGroupId: newSiteGroupId, siteId },
    });
  };

  return (
    <>
      <h3>{label}</h3>
      <FieldArray name={name}>
        {({ push, remove }) => (
          <>
            <FieldArray
              name="siteGroupSites"
              render={() => (
                <div>
                  <div>
                    {values?.siteGroupSites.map(
                      (siteGroup: any, index: number) => {
                        const treatmentSiteOptions = siteGroups
                          .map((item: any, index: number) => {
                            return {
                              label: `Site Group ${index + 1}`,
                              value: item.id,
                            };
                          })
                          .filter(
                            (option: any) => option.value !== siteGroup.id
                          );
                        const handleDeleteSiteTemplate = (
                          rowIndex: number,
                          siteId: string
                        ) => {
                          remove(rowIndex);
                          deleteSiteTemplate({ variables: { siteId } });
                        };
                        return (
                          <SiteWrapperDiv key={siteGroup.id}>
                            <FlexStartDiv>
                              <StyledGroupNameWrapper
                                data-test-id={`siteGroupNames[${index}]`}
                              >{`Site Group ${
                                index + 1
                              }`}</StyledGroupNameWrapper>
                              <IconButton
                                style={{ marginLeft: "auto" }}
                                onClick={() => {
                                  deleteSiteGroupTemplate({
                                    variables: { siteGroupId: siteGroup.id },
                                  });
                                }}
                                disabled={!isActive}
                                size="large"
                              >
                                <DeleteIcon
                                  style={{ color: colors.destructive.base }}
                                />
                              </IconButton>
                            </FlexStartDiv>
                            <TreatmentSitesTable
                              options={treatmentSiteOptions}
                              handleUpdate={handleUpdateSiteTemplate}
                              handleDelete={handleDeleteSiteTemplate}
                              siteTemplate={siteGroup.siteTemplate}
                              siteGroupCount={values?.siteGroupSites?.length}
                              isActive={isActive}
                              index={index}
                              updateSiteName={updateSiteTemplateName}
                              siteOptions={options}
                            />
                            <StyledSiteSelectDiv>
                              <div
                                style={{
                                  fontWeight: "bold",
                                  marginBottom: "10px",
                                }}
                              >
                                Add New Site
                              </div>
                              <Autocomplete
                                id={`site-group-site-select[${index}]`}
                                options={options}
                                autoHighlight
                                getOptionLabel={(option: {
                                  label: string;
                                  value: string;
                                }) => option.label}
                                isOptionEqualToValue={(
                                  option: { label: string; value: string },
                                  value: { value: string }
                                ): boolean => value.value === option.value}
                                onInputChange={(_, value) => setSiteName(value)}
                                onChange={(
                                  _event,
                                  value: { label: string; value: string } | null
                                ) => {
                                  if (value !== null) {
                                    !value.value.includes(siteName) &&
                                      push(siteName);
                                    createSiteTemplate({
                                      variables: {
                                        templateId,
                                        siteName: value.value,
                                        siteGroupId: siteGroup.id,
                                      },
                                    });
                                  }
                                }}
                                disabled={!isActive}
                                renderInput={(params) => (
                                  <TextField
                                    {...params}
                                    label={autocompleteLabel}
                                    variant="outlined"
                                  />
                                )}
                              />
                            </StyledSiteSelectDiv>
                            {siteGroup.siteTemplate.length === 0 &&
                              errors.site && (
                                <ErrorMessage>{errors.site}</ErrorMessage>
                              )}
                          </SiteWrapperDiv>
                        );
                      }
                    )}
                  </div>
                  <FlexStartDiv>
                    <DebouncedButton
                      Component={Button}
                      id="addSiteGroup"
                      disabled={emptySiteGroup || !isActive}
                      delay={5000}
                      onClick={() => {
                        const nextNumber = Number(siteGroups?.length) + 1 || 1;
                        const siteGroupName = `Site Group ${nextNumber}`;
                        addSiteGroupTemplate({
                          variables: { templateId, name: siteGroupName },
                        });
                      }}
                    >
                      New Site Group
                    </DebouncedButton>
                  </FlexStartDiv>
                </div>
              )}
            />
            {errors.siteGroupSites && (
              <ErrorMessage>{errors.siteGroupSites}</ErrorMessage>
            )}
          </>
        )}
      </FieldArray>
    </>
  );
};

export default TreatmentSites;
