import { Switch } from "@mui/material";
import { colors } from "@lumonus/gc-ui";
import { styled } from "@mui/material/styles";

const GreenSwitch = styled(Switch)({
  switchBase: {
    "&$checked": {
      color: colors.primary.base,
    },
    "&$checked + $track": {
      backgroundColor: colors.primary.base,
    },
  },
  checked: {},
  track: {},
});

export default GreenSwitch;
