import styled from "styled-components";
import { Select } from "@mui/material";
import { colors } from "@lumonus/gc-ui";

const OarSelect = styled(Select)`
&& {
  border-width: 1px;
  border-color: ${colors.neutral.grey};
  margin: 6px;
  padding-left: 6px;
  }
}}
`;

export default OarSelect;
