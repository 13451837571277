import styled from "styled-components";
import { Select, SelectProps } from "@mui/material";
import { colors } from "@lumonus/gc-ui";

interface Props extends SelectProps {
  width?: string;
}

const PeerReviewSelect = styled(Select)<Props>`
&& {
  border-width: 1px;
  border-color: ${colors.neutral.grey};
  margin: 6px;
  padding-left: 6px;
  width: ${(props) => props.width || "unset"};
  }
}}
`;

export default PeerReviewSelect;
