import React from "react";
import { FieldProps, getIn } from "formik";
import {
  FormControl,
  MenuItem,
  Checkbox,
  ListItemText,
  Chip,
  InputLabel,
  SelectChangeEvent,
} from "@mui/material";

import Select from "components/base/Select";
import FormRow from "components/base/FormRow";
import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";
import InvalidMessage from "components/base/InvalidMessage";

const StyledCheckbox = styled(Checkbox)`
  && {
    &.Mui-checked {
      color: ${(props) => (props.checked ? colors.primary.base : "white")};
    }
  }
`;

const ChipsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start !important;
  padding: 0px !important;
`;

const StyledChip = styled(Chip)`
  && {
    margin: 2px;
  }
`;

const StyledFormControl = styled(FormControl)`
  && {
    min-width: unset;
  }
`;

interface Props extends FieldProps {
  label: string;
  options: any[];
  handleMutation: any;
  noEmptyOption: boolean;
  toggleActive?: boolean;
  isActive: boolean;
  readOnly: boolean;
  placeholder: string;
  allSelected?: boolean;
  allSelectedLabel?: string;
  labelXs?: any;
  diffValueToLabel?: boolean;
}

const MultiSelectField = ({
  field,
  form,
  label,
  options,
  handleMutation,
  isActive,
  toggleActive,
  readOnly,
  placeholder = "Please select",
  allSelected,
  allSelectedLabel,
  labelXs,
  diffValueToLabel,
}: Props) => {
  const { errors, touched, setFieldValue } = form;
  const { name = "", value = [] } = field;
  const fieldError = getIn(errors, name);
  const fieldTouched = getIn(touched, name);

  const handleChange = (event: SelectChangeEvent<unknown>) => {
    const value = event.target.value as string[];
    setFieldValue(name, value);
    handleMutation && handleMutation(name, JSON.stringify(value));
  };

  const handleRender = (selected: any) => {
    let selectedOpts = [];
    if (diffValueToLabel) {
      const opts = options.map((opt) => ({ [opt.value]: opt.label }));
      const optsMap = Object.assign({}, ...opts);
      selectedOpts = selected?.map((value: string) => (
        <StyledChip key={value} label={optsMap[value] || value} />
      ));
    } else {
      selectedOpts = selected?.map((value: string) => (
        <StyledChip key={value} label={value} />
      ));
    }
    return (
      <ChipsWrapper>
        {allSelected === true && allSelectedLabel ? (
          <StyledChip key={allSelectedLabel} label={allSelectedLabel} />
        ) : (
          selectedOpts
        )}
      </ChipsWrapper>
    );
  };

  return (
    <FormRow
      label={label}
      disabled={!isActive}
      name={name}
      toggleActive={toggleActive}
      readOnly={readOnly}
      labelXs={labelXs}
      errorChildren={
        Boolean(fieldTouched) &&
        Boolean(fieldError) && <InvalidMessage text={fieldError} />
      }
    >
      <StyledFormControl variant="outlined">
        <InputLabel shrink={false} id="select-label">
          {value.length ? "" : placeholder}
        </InputLabel>
        <Select
          multiple
          labelId={`${name}-select-label-id`}
          id={`${name}-select-id`}
          value={value}
          renderValue={handleRender}
          disabled={!isActive || readOnly}
          onChange={handleChange}
          inputProps={{ "data-test-id": `${name}-select` }}
          error={Boolean(fieldError) && Boolean(fieldTouched)}
          hasClearButton={false}
        >
          {options?.map(
            (option: { label: string; value: any }, idx: number) => (
              <MenuItem key={idx} value={option.value}>
                <StyledCheckbox checked={value.indexOf(option.value) > -1} />
                <ListItemText primary={option.label} />
              </MenuItem>
            )
          )}
        </Select>
      </StyledFormControl>
    </FormRow>
  );
};

export default MultiSelectField;
