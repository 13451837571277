import React, { Fragment } from "react";
import { FieldProps, useFormikContext } from "formik";
import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";
import FormRow from "components/base/FormRow";
import { parseDose } from "components/formFields/DoseField";
import { StyledTextField, StyledDiv } from "components/formFields/DoseShared";

const FractionDoseInput = styled(StyledTextField)`
  & input {
    background-color: ${colors.neutral.lightestGrey};
  }
`;

interface Props extends FieldProps {
  label: string;
  isActive?: boolean;
  toggleActive?: boolean;
  handleMutation: any;
  type: string;
}

const FractionDose = ({ field, label, isActive }: Props) => {
  const { name } = field;
  const { values }: { values: any } = useFormikContext();
  const doseValues = parseDose(values.Dose);
  const fraction = Number(values.Fractions);
  const [disabled] = React.useState<boolean>(
    isActive === undefined ? false : !isActive
  );
  const fractionDose = Array.isArray(doseValues)
    ? doseValues.map((dose: any) => {
        return (
          Math.round((Number(dose) / fraction + Number.EPSILON) * 100) / 100
        );
      })
    : [];

  return (
    <Fragment>
      <FormRow label={label} name={name} disabled={disabled}>
        <StyledDiv>
          {fractionDose.map((v: any, index: number) => (
            <FractionDoseInput
              name={name}
              key={index}
              value={v || ""}
              id="outlined-basic"
              variant="outlined"
              size="medium"
              disabled={disabled}
              inputProps={{ "data-test-id": `${name}-input-${index}` }}
            />
          ))}
        </StyledDiv>
      </FormRow>
    </Fragment>
  );
};

export default FractionDose;
