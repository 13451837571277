import React from "react";
import styled from "styled-components";
import { Button, ButtonProps } from "@mui/material";
import { colors } from "@lumonus/gc-ui";

interface Props extends ButtonProps {
  solidgreen?: string;
}

const FontColor = (props: Props) => {
  if (props.solidgreen === "true") {
    return "white";
  }
  if (props.disabled) {
    return colors.neutral.darkGrey;
  }
  return colors.primary.base;
};

const BackgroundColor = (props: Props) => {
  if (props.solidgreen === "true") {
    if (props.disabled) {
      return colors.neutral.darkGrey;
    }
    return colors.primary.base;
  }
  return "default";
};

const StyledButton = styled(Button)<Props>`
&& {
    text-transform: none;
    color: ${(props: Props) => FontColor(props)};
    border-color: ${(props: Props) =>
      props.disabled ? colors.neutral.darkGrey : colors.primary.base};
    background-color: ${(props: Props) => BackgroundColor(props)};
    border-radius: 18px;
    min-height: 2em;
    min-width: 120px;
    width: auto;
    height: 36px;
    font-size: 16px;
    border-width: 2px;

    line-height: 24px;
    padding: 4px 12px;
    text-align: center;
  }
}}
`;

const GreenButton = (props: Props) => {
  return <StyledButton variant="outlined" {...props} />;
};

export default GreenButton;
