import React from "react";
import { Formik, Form } from "formik";

import FormPage from "components/base/FormPage";
import BulkEditCard from "components/bulkEdit/BulkEditCard";

import { constraints } from "components/oars/OarTable";
import {
  SmallTableCell,
  MediumTableCell,
  LargeTableCell,
} from "components/TableStyles";
import { Table, TableHead, TableRow, TableBody } from "@mui/material";
import { colors } from "@lumonus/gc-ui";
import { formatFieldName } from "shared/utils";

interface Props {
  data: [
    {
      defaultValue: string;
      field: {
        name: string;
      };
    }
  ];
  oar: any;
}

const formatOars = (oars: any) =>
  oars.map((oarValues: any, organIndex: number) => {
    // Each organ will be its own row
    const oarConstraints = constraints(oarValues).map(
      (cont: any, index: number) => (
        <span key={`oar-${index}`}>
          {cont}
          <br />
        </span>
      )
    );

    return (
      <TableRow key={`oar-row-${organIndex}`}>
        {organIndex === 0 && (
          <MediumTableCell rowSpan={oars.length}>OAR:</MediumTableCell>
        )}
        <MediumTableCell>{oarValues.organ}</MediumTableCell>
        <LargeTableCell>{oarConstraints}</LargeTableCell>
      </TableRow>
    );
  });

const Review = ({ data, oar }: Props) => {
  const bulkEditTemplates = JSON.parse(
    sessionStorage.getItem("bulkEditList") || "[]"
  );
  const hasOars = oar?.length > 0;

  const volumingCardData = (
    <Table>
      <TableHead>
        <TableRow>
          <MediumTableCell style={{ fontWeight: "bold" }}>
            Field:
          </MediumTableCell>
          <LargeTableCell
            colSpan={hasOars ? 2 : 1}
            style={{ fontWeight: "bold" }}
          >
            New Value:
          </LargeTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data?.map((rule: any, index: number) => {
          const isOar = rule.field.name === "OAR";
          return isOar ? (
            formatOars(oar)
          ) : (
            <TableRow key={index}>
              <MediumTableCell>
                {formatFieldName(rule.field.name)}:
              </MediumTableCell>
              <LargeTableCell colSpan={hasOars ? 2 : 1}>
                {rule.defaultValue}
              </LargeTableCell>
            </TableRow>
          );
        })}
      </TableBody>
    </Table>
  );

  const selectionCardData = (
    <Table>
      <TableHead>
        <TableRow>
          <SmallTableCell style={{ fontWeight: "bold" }}>ID:</SmallTableCell>
          <LargeTableCell style={{ fontWeight: "bold" }}>Name:</LargeTableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {bulkEditTemplates?.map((template: any) => (
          <TableRow key={template.id}>
            <SmallTableCell>{template.id}</SmallTableCell>
            <LargeTableCell>{template.name}</LargeTableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );

  return (
    <>
      <Formik initialValues={[]} onSubmit={() => {}}>
        <Form>
          <FormPage heading={"Review & Submit"}>
            <p>Please review and submit bulk edit details.</p>
            <BulkEditCard
              heading={`Selected Templates (${bulkEditTemplates?.length})`}
              borderColor={colors.neutral.darkGrey}
            >
              {selectionCardData}
            </BulkEditCard>
            <BulkEditCard heading="Voluming" borderColor={colors.primary.base}>
              {volumingCardData}
            </BulkEditCard>
          </FormPage>
        </Form>
      </Formik>
    </>
  );
};

export default Review;
