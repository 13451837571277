import { Link } from "react-router-dom";
import { colors } from "@lumonus/gc-ui";
import styled from "styled-components";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import DragIndicatorIcon from "@mui/icons-material/DragIndicator";
import { Popover as MUIPopover } from "@mui/material";

export const StyledDragIndicatorIcon = styled(DragIndicatorIcon)`
  margin-right: 5px;
  fill: ${colors.neutral.darkGrey} !important;
`;

export const ProtocolHeader = styled.div`
  width: 100%;
  border-bottom: 1px solid silver;
  display: flex;
  padding: 8px;
  div {
    display: flex;
    align-items: center;
    margin: 0 25px;
  }
`;

export const MainHeading = styled.h4`
  font-size: 24px;
  line-height: 28px;
  font-weight: 700;
  margin: 24px 0;
  flex: 0 0 100%;
`;

export const DrugOrderPanel = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  height: 100%;
  width: 307px;
  background-color: ${colors.neutral.lightestGrey};
  padding: 24px;
  border-radius: 8px;
  margin-right: 24px;
`;

export const CycleDrugOrderInfo = styled.div`
  font-weight: 700;
  color: ${colors.neutral.darkGrey};
  padding-top: 26px;
  font-size: 14px;
  line-height: 20px;
`;

export const Popover = styled(MUIPopover)`
  .MuiPopover-paper {
    overflow: visible;
    padding: 16px;
    border-radius: 8px;
    font-weight: 700;
    box-shadow: 0px 3px 5px grey;
    ::before {
      content: "";
      position: absolute;
      top: -8px;
      left: 16px;
      z-index: -1;
      border: solid #fff;
      border-width: 8px 8px;
      transform: rotate(45deg);
      box-shadow: 1px 1px 4px grey;
    }
    ::after {
      content: "";
      position: absolute;
      top: -8px;
      left: 16px;
      z-index: -0;
      border: solid #fff;
      border-width: 8px 8px;
      transform: rotate(45deg);
    }
  }
`;

export const PopoverButtons = styled.div`
  margin-top: 16px;
  display: flex;
`;

export const DrugOrderCount = styled.span`
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  height: 16px;
  min-width: 16px;
  background-color: ${colors.information.base};
  color: white;
  border-radius: 16px;
  padding: 0 3px;
  margin-left: 8px;
`;

export const MainContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0px 24px;
  height: calc(100% - 155px);
`;

export const CyclePanel = styled.div`
  flex: 1;
  min-width: 0px;
  height: 100%;
  padding: 24px;
  border-radius: 8px;
  box-shadow: rgba(99, 99, 99, 0.3) 0px 2px 8px 0px;
`;

export const CycleButtonBar = styled.div`
  display: flex;
  justify-content: start;
  gap: 24px;
  width: 100%;
  overflow-x: scroll;
  padding-bottom: 8px;
  margin-top: 16px;

  ::-webkit-scrollbar {
    -webkit-appearance: none;
    width: 3px;
    height: 10px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 6px;
    background-color: ${colors.neutral.grey};
    box-shadow: 0 0 1px rgba(255, 255, 255, 0.5);
  }
`;

export const CycleButton = styled(Link)`
  display: flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  width: 56px;
  height: 56px;
  border-radius: 50%;
  font-weight: 700;
  color: ${colors.neutral.black};
  text-decoration: none;
  font-size: 16px;
  background-color: ${colors.neutral.lightestGrey};
  cursor: pointer;
  &.active {
    color: white !important;
    background-color: ${colors.primary.base} !important;
  }
  &.continuous {
    pointer-events: none;
  }
  &:hover {
    background-color: ${colors.neutral.lightGrey};
    text-decoration: none;
    color: inherit;
  }
`;

export const DrugNameCard = styled.div`
  background-color: white;
  border: 1px solid ${colors.neutral.lightGrey};
  height: 56px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: box-shadow 0.1s ease-in-out;
`;

export const DraggableDrugNameCard = styled(DrugNameCard)`
  &:hover {
    box-shadow: 0px 2px 8px ${colors.neutral.lightGrey};
    position: relative;
    z-index: 50;
  }
`;

export const DrugNameCardInner = styled.div`
  flex: 1;
  padding: 0px 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
`;

export const RemoveDrugOrderIcon = styled(RemoveCircleIcon)`
  margin: 0px 8px;
  cursor: pointer;
  color: ${colors.neutral.lightGrey};
  &:hover {
    fill: ${colors.destructive.base};
  }
`;

export const DayHeader = styled.div`
  width: 100%;
  padding-bottom: 16px;
`;

export const TemplateName = styled.div`
  color: ${colors.primary.base};
`;

export const ScrollingContainer = styled.div`
  height: calc(100% - 100px);
  overflow: auto;
`;

export const DayContainer = styled.div`
  background-color: ${colors.neutral.lightestGrey};
  padding: 16px;
  margin-top: 12px;
  border-radius: 8px;
  margin-bottom: 24px;
  :last-of-type {
    margin-bottom: 16px;
  }
`;

export const DrugSequence = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 36px;
  border-right: 1px solid ${colors.neutral.lightGrey};
`;
