import React from "react";
import { Draggable } from "react-beautiful-dnd";
import { DraggableProvided, DraggableStateSnapshot } from "react-beautiful-dnd";
import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";

export interface DraggableTableRowProps {
  children: React.ReactNode;
  draggableId: string;
  index: number;
  isDraggable: boolean;
}
const Row = styled.tr<{ isDragging: boolean }>`
  /* stylelint-disable comment-empty-line-before */
  ${(props: { isDragging: boolean }) =>
    props.isDragging
      ? `
    background: ${colors.neutral.grey};
    /* maintain cell width while dragging */
    display: table;
  `
      : ""}/* stylelint-enable */;
  height: 48px;
  & td:first-child {
    left: 0;
    border-left: 0;
    position: -webkit-sticky;
    position: sticky;
  }
`;

const DraggableTableRow = (props: DraggableTableRowProps) => {
  const { isDraggable, draggableId, index, children, ...otherProps } = props;
  const modifiedChildren = (
    children: any,
    additionalProps: any
  ): React.ReactNode => {
    return React.Children.map(children, (child, index) => {
      if (index === 0) {
        return React.cloneElement(child, { ...additionalProps });
      }
      return child;
    });
  };
  return (
    <Draggable
      isDragDisabled={!props.isDraggable}
      draggableId={props.draggableId}
      index={props.index}
    >
      {(provided: DraggableProvided, snapshot: DraggableStateSnapshot) => (
        <Row
          ref={provided.innerRef}
          isDragging={snapshot.isDragging}
          {...provided.draggableProps}
          {...otherProps}
        >
          {modifiedChildren(props.children, provided.dragHandleProps)}
        </Row>
      )}
    </Draggable>
  );
};

export default DraggableTableRow;
