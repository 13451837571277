import GCTheme, {
  MainLogo as GCMainLogo,
  favicon as GCFavicon,
} from "./GC/theme";
import LumonusTheme, {
  MainLogo as LumonusMainLogo,
  favicon as LumonusFavicon,
} from "./Lumonus/theme";

export type PaletteColour =
  | "default"
  | "primary"
  | "secondary"
  | "error"
  | "warning"
  | "info"
  | "success"
  | "text"
  | "skeleton";
export const MainLogo = GCMainLogo;
export const favicon = GCFavicon;
export const theme = GCTheme;
export default theme;
