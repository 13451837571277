import React from "react";
import { IconButton, TableCell, TableRow } from "@mui/material";
import Tooltip from "components/base/Tooltip";
import { RegionInterface } from "shared/utils";
import { TemplateColumnInterface, StatusInterface } from "./TableInterfaces";
import {
  StyledTableSortLabel,
  StatusContainer,
  Dot,
} from "components/TableStyles";
import {
  EditOutlined,
  ArchiveOutlined,
  FileCopyOutlined,
} from "@mui/icons-material";
import { colors } from "@lumonus/gc-ui";
import CircularProgress from "components/base/CircularProgress";

export const templateColumn = (
  col: TemplateColumnInterface,
  key: string,
  orderBy: string,
  orderDirection: "asc" | "desc",
  onClickCallback: (attribute: string) => void
) => (
  <TableCell
    key={key}
    sortDirection={orderBy === col?.attribute ? orderDirection : false}
  >
    <StyledTableSortLabel
      active={orderBy === col.attribute}
      direction={orderBy === col.attribute ? orderDirection : "asc"}
      onClick={() => onClickCallback(col.attribute)}
    >
      {col.label}
    </StyledTableSortLabel>
  </TableCell>
);

interface StatusProps {
  color: string;
  children?: React.ReactNode;
}

export const StatusTrafficLight = (props: StatusProps) => (
  <StatusContainer>
    <Dot
      style={{
        marginRight: "12px",
        backgroundColor: props.color,
      }}
    />
    {props.children}
  </StatusContainer>
);

interface IconButtonProps {
  onClick?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  placement?:
    | "bottom-end"
    | "bottom-start"
    | "bottom"
    | "left-end"
    | "left-start"
    | "left"
    | "right-end"
    | "right-start"
    | "right"
    | "top-end"
    | "top-start"
    | "top";
  id?: string;
}

export const EditIcon = ({ onClick, placement, id }: IconButtonProps) => (
  <Tooltip title="Edit" placement={placement || "top"}>
    <IconButton onClick={onClick} data-test-id={id} size="large">
      <EditOutlined></EditOutlined>
    </IconButton>
  </Tooltip>
);

export const ArchiveIcon = ({ onClick, placement, id }: IconButtonProps) => (
  <Tooltip title="Archive" placement={placement || "top"}>
    <IconButton onClick={onClick} data-test-id={id} size="large">
      <ArchiveOutlined></ArchiveOutlined>
    </IconButton>
  </Tooltip>
);

export const CloneIcon = ({ onClick, placement, id }: IconButtonProps) => (
  <Tooltip title="Clone" placement={placement || "top"}>
    <IconButton onClick={onClick} data-test-id={id} size="large">
      <FileCopyOutlined></FileCopyOutlined>
    </IconButton>
  </Tooltip>
);

export const getCountryList = (countries: RegionInterface[]) =>
  countries.length ? countries.map((item) => item.name).join(", ") : "";

export const ROWS_PER_PAGE = [25, 50, 100];

interface PlaceholderRowProps {
  loading: boolean;
  colSpan: number;
}

export const PlaceholderRow = ({ loading, colSpan }: PlaceholderRowProps) => (
  <TableRow>
    <TableCell colSpan={colSpan} align="center">
      {loading ? <CircularProgress /> : "No results found!"}
    </TableCell>
  </TableRow>
);

const StatusLookup: StatusInterface = {
  approve: "Approved",
  test: "Test",
  draft: "Draft",
};

const StatusColors: StatusInterface = {
  approve: colors.success.base,
  test: colors.information.base,
  draft: colors.neutral.darkGrey,
};

export const BeautifyStatus = (status: string): string =>
  StatusLookup[status] || status;
export const ColorFromStatus = (status: string): string =>
  StatusColors[status] || "black";
