import React from "react";
import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";
import { Grid, TextField, IconButton, TextFieldProps } from "@mui/material";
import AddCircleOutline from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import { GenericRule } from "./constants";

// eslint-disable-next-line
// @ts-ignore
interface StyledTextFieldProps extends TextFieldProps {
  marginleft?: string;
}

const StyledTextField = styled(TextField)<StyledTextFieldProps>`
  border-color: ${colors.neutral.grey};
  margin: 6px;
  margin-left: ${(props) => props.marginleft || undefined};
  padding-left: 6px;
  width: 80%;
`;

interface Props {
  id: string;
  index: number;
  rule: GenericRule;
  readOnly?: boolean;
  defaultValue: string[];
  onChange: (index: number, value: GenericRule) => void;
  onBlur?: () => void;
}

const PeerReviewTextField = (props: Props) => {
  const { id, index, readOnly, defaultValue, rule, onBlur, onChange } = props;
  const changeTextField = (newVal: string[]) =>
    onChange(index, {
      id: rule?.id,
      field: rule?.field,
      defaultValue: newVal,
      operator: rule?.operator,
    });

  return (
    <Grid item xs={6}>
      {defaultValue.map((value: string, index: number) => {
        const marginleft = index !== defaultValue.length - 1 ? "54px" : "6px";
        const showRemoveButton = defaultValue.length > 1;
        return (
          <Grid item key={index}>
            {index === defaultValue.length - 1 && (
              <IconButton
                disabled={readOnly}
                data-test-id={`addValue-${id}`}
                onClick={() => changeTextField([...defaultValue, ""])}
                size="large"
              >
                <AddCircleOutline
                  style={{
                    color: !readOnly
                      ? colors.primary.base
                      : colors.neutral.grey,
                  }}
                />
              </IconButton>
            )}
            <StyledTextField
              id={`value-${id}-${index}`}
              type="text"
              value={value}
              marginleft={marginleft}
              onChange={(e) => {
                defaultValue.splice(index, 1, e.target.value);
                changeTextField(defaultValue);
              }}
              disabled={readOnly}
              onBlur={(): void => {
                if (onBlur) onBlur();
              }}
              InputProps={{
                endAdornment: showRemoveButton && (
                  <IconButton
                    id={`remove-row-${id}-${index}`}
                    style={{ padding: "4px" }}
                    onClick={() => {
                      const newValues = defaultValue.slice();
                      newValues.splice(index, 1);
                      changeTextField(newValues);
                    }}
                    size="large"
                  >
                    <RemoveCircleOutline
                      style={{ color: colors.destructive.base }}
                    />
                  </IconButton>
                ),
              }}
            />
          </Grid>
        );
      })}
    </Grid>
  );
};

export default PeerReviewTextField;
