import { InfoOutlined } from "@mui/icons-material";
import React from "react";
import styled from "styled-components";

const Banner = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-basis: 125px;
  background-color: #2196f3;
  border-radius: 4px;
  line-height: 1.5ch;
  padding: 3px;
  box-shadow: 0px 3px 5px grey;
`;

const BannerText = styled.div`
  display: flex;
  text-align: center;
  font-size: small;
  font-weight: 700;
`;

const showBanner = () =>
  !window.location.href.includes("careplan.genesiscare.com");

const bannerAlert = () => {
  const url = window.location.href;
  if (url.includes("careplan-dev.testing-genesis.care")) {
    return <BannerText>This is a review environment!</BannerText>;
  }
  if (url.includes("careplan.testing-genesis.care")) {
    return (
      <BannerText>
        This is a<br />
        pre-production environment!
      </BannerText>
    );
  }
  return <BannerText>This is a development environment!</BannerText>;
};

const EnvironmentBanner = () =>
  showBanner() ? (
    <Banner>
      <InfoOutlined />
      {bannerAlert()}
    </Banner>
  ) : null;

export default EnvironmentBanner;
