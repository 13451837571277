import {
  Stepper,
  StepLabel,
  StepContent,
  StepLabelProps,
  StepContentProps,
} from "@mui/material";
import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";

interface StyledStepLabelProps extends StepLabelProps {
  valid?: string;
}

interface StyledStepContentProps extends StepContentProps {
  valid?: string;
}

export const StyledStepper = styled(Stepper)`
  padding: 8px;
`;

export const StyledDrawer = styled.div`
  height: 100%;
  width: 200px;
  padding-top: 20px 8px;
  border-right: 1px solid silver;
  .MuiStepIcon-root.MuiStepIcon-active {
    color: ${colors.primary.base};
  }
  .MuiPaper-root {
    background-color: transparent;
  }
`;

export const StyledStepContentDiv = styled.div`
  padding: 3px 0;
`;

export const StyledStepLabel = styled(StepLabel)<StyledStepLabelProps>`
  .MuiStepLabel-label {
    cursor: ${(props) => (props.valid === "true" ? "pointer" : "not-allowed")};
    font-size: 16px;
    color: ${colors.neutral.black};
  }
`;

export const StyledStepContent = styled(StepContent)<StyledStepContentProps>`
  cursor: ${(props) => (props.valid === "true" ? "pointer" : "not-allowed")};
  padding-left: 10px;
  padding-right: 0;
`;
