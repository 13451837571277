import styled from "styled-components";
import { colors } from "@lumonus/gc-ui";
export interface ColumnInfo {
  key: number;
  title: string;
  subTitle?: string;
}
export const StyledTable = styled.table`
  display: block;
  overflow-y: auto;
  max-height: calc(100vh - 457px);
  background-color: ${colors.neutral.lightestGrey};
  border-top: 0 solid ${colors.neutral.lightGrey};
  scrollbar-width: none;

  &::-webkit-scrollbar {
    display: none;
  }
`;

export const StyledTableHeaderRow = styled.tr`
  height: 40px;
  background-color: ${colors.neutral.lightestGrey};
  & th:first-child {
    cursor: inherit;
    position: -webkit-sticky;
    position: sticky;
    left: 0;
    z-index: 2;
    text-align: left;
    background-color: ${colors.neutral.lightestGrey};
    color: ${colors.neutral.black};
    border-left: 1px solid ${colors.neutral.lightestGrey} !important;
  }
`;

export const StyledTableHeaderCell = styled.th<{ empty: boolean }>`
  text-align: center;
  border-left: 1px solid ${colors.neutral.grey} !important;
  border-bottom: 1px solid ${colors.neutral.grey} !important;
  border-right: 1px solid ${colors.neutral.grey} !important;
  background-color: white;
  vertical-align: middle;
  position: sticky;
  top: 0;
  z-index: 1;
  border-top: 0 solid ${colors.neutral.lightGrey} !important;
  top: -1px !important;
  min-width: 60px !important;

  background-color: ${(props: { empty: boolean }) =>
    props.empty ? colors.neutral.lightGrey : colors.neutral.white};
  color: ${(props: { empty: boolean }) =>
    props.empty ? colors.neutral.darkGrey : colors.neutral.black};
  cursor: default;
`;

export const StyledTableCell = styled.td<{
  empty?: boolean;
  minWidth?: number;
}>`
  background-color: white;
  border: 1px solid ${colors.neutral.grey};
  vertical-align: middle;
  background-color: ${(props: { empty?: boolean; minWidth?: number }) =>
    props.empty ? colors.neutral.lightGrey : colors.neutral.white};
  min-width: ${(props: { empty?: boolean; minWidth?: number }) =>
    props.minWidth}px;
`;

export const StyledRow = styled.tr`
  height: 48px;
  & td:first-child {
    left: 0;
    border-left: 0;
    position: -webkit-sticky;
    position: sticky;
  }
`;
