import React, { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import LoopIcon from "@mui/icons-material/Loop";
import Tooltip from "components/base/Tooltip";
import { SelectOptionsInterface } from "interfaces/common";
import {
  CycleDayInterface,
  ProtocolDrugOrder,
  ProtocolDrugOrderInCycle,
  TemplateInterface,
} from "interfaces/templates";
import EvidenceLink from "components/base/EvidenceLink";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";
import SearchBox, { Option } from "components/base/SearchDropdown";
import { SEARCH_DRUG_ORDER } from "graphql/FormularyQueries";
import { DRUG_ORDER_IN_CYCLE } from "graphql/ProtocolQueries";
import CycleDrugOrderModal from "components/CycleDrugOrderModal";
import { useLazyQuery, useMutation, useQuery } from "@apollo/client";
import { UPDATE_CYCLE_DRUG_ORDER } from "graphql/ProtocolQueries";
import { Button } from "@lumonus/gc-ui";
import {
  ProtocolHeader,
  TemplateName,
  MainContainer,
  MainHeading,
  DrugOrderPanel,
  CycleDrugOrderInfo,
  DrugOrderCount,
  ScrollingContainer,
  DrugNameCard,
  DrugNameCardInner,
  RemoveDrugOrderIcon,
  CyclePanel,
  CycleButtonBar,
  CycleButton,
  PopoverButtons,
  Popover,
} from "./CycleStyledComponents";

import CycleTable from "./CycleTable";
import { CycleSchedule, ProtocolInformation } from "./interfaces";

interface Props {
  template: TemplateInterface;
  selectOptions: SelectOptionsInterface;
  disableEditing: boolean;
}

interface ParamTypes {
  templateId: string;
  selectedCycle: string;
}
const displayDose = (dose: number | null, units: string | undefined) => {
  return units === "International Units" ? (
    <span>
      {dose}
      <br />
      International Units
    </span>
  ) : (
    `${dose} ${units}`
  );
};
const getProtocolInformation = (
  template: TemplateInterface,
  drugOrderInCycle: ProtocolDrugOrderInCycle[]
): ProtocolInformation => {
  // Create drugOrderInCycle dictionary for easy lookup
  const drugOrderInCycleDict = drugOrderInCycle.reduce(
    (result: { [key: string]: ProtocolDrugOrderInCycle }, drugOrder) => {
      result[drugOrder.id] = drugOrder;
      return result;
    },
    {}
  );

  const cycleScheduleDict = template.doses.reduce(
    (
      cycle: { [key: number]: CycleSchedule },
      dose
    ): {
      [key: number]: CycleSchedule;
    } => {
      if (
        dose.drugOrderInCycle.id === null ||
        dose.drugOrderInCycle.id === undefined
      )
        return cycle;
      if (drugOrderInCycleDict[dose.drugOrderInCycle.id] === undefined)
        return cycle;
      const doseDrugOrder =
        drugOrderInCycleDict[dose.drugOrderInCycle.id].protocolDrugOrder
          .drugOrder;
      const position = drugOrderInCycleDict[dose.drugOrderInCycle.id].position;
      if (dose.cycle in cycle) {
        const foundDrugOrder = cycle[dose.cycle].drugOrder.filter(
          (drugOrder) => drugOrder.drugOrderId.toString() === doseDrugOrder.id
        );
        if (foundDrugOrder.length > 0) {
          foundDrugOrder[0].days.push(dose.day);
        } else {
          cycle[dose.cycle].drugOrder.push({
            drugOrderId: parseInt(doseDrugOrder.id || "0"),
            days: [dose.day],
            drugOrderName: doseDrugOrder.name,
            dose: displayDose(
              doseDrugOrder.minimumDose !== 0 &&
                doseDrugOrder.minimumDose !== null
                ? doseDrugOrder.minimumDose
                : doseDrugOrder.doseBasis,
              doseDrugOrder.doseUnit.name
            ),
            order: position,
            type: doseDrugOrder.type,
          });
        }
      } else {
        cycle[dose.cycle] = {
          cycleId: dose.cycle,
          drugOrder: [
            {
              drugOrderId: parseInt(doseDrugOrder.id || "0"),
              days: [dose.day],
              drugOrderName: doseDrugOrder.name,
              dose: displayDose(
                doseDrugOrder.minimumDose !== 0 &&
                  doseDrugOrder.minimumDose !== null
                  ? doseDrugOrder.minimumDose
                  : doseDrugOrder.doseBasis,
                doseDrugOrder.doseUnit.name
              ),
              order: position,
              type: doseDrugOrder.type,
            },
          ],
        };
      }
      return cycle;
    },
    {}
  );
  return {
    protocolId: template.id,
    numberOfCycles: template.numberOfCycles,
    numberOfDaysPerCycle: template.daysPerCycle,
    isContinuous: template.continuous,
    cycleSchedule: Object.values(cycleScheduleDict),
    drugOrderInCycle: drugOrderInCycle,
  };
};

const Cycle = ({ template, disableEditing }: Props) => {
  const { selectedCycle } = useParams<ParamTypes>();
  const [modalOpen, setModalOpen] = useState(false);
  const [editingDrugOrderDays, setEditingDrugOrderDays] = useState(false);
  const [uniqueDrugOrders, setUniqueDrugOrders] = useState<ProtocolDrugOrder[]>(
    []
  );
  const formattedDays: Record<number, CycleDayInterface> = {};
  template.doses.forEach((dose) => {
    if (dose.cycle === parseInt(selectedCycle)) {
      formattedDays[dose.day] = formattedDays[dose.day] || { doses: [] };
      formattedDays[dose.day].doses.push(dose);
    }
  });

  const timer = useRef<number | undefined>(undefined);
  const [searchTerm, setSearchTerm] = useState("");
  const [foundItems, setFoundItems] = useState<Option[]>([]);
  const [selectedDrugOrder, setSelectedDrugOrder] = useState<Option | null>(
    null
  );
  const [searchOpen, setSearchOpen] = useState(false);

  const [getDrugOrderSearchResults] = useLazyQuery(SEARCH_DRUG_ORDER, {
    onCompleted: (data) => {
      const options: Option[] = data?.drugOrderSearch?.drugOrders?.map(
        (order: { name: string; id: string }): Option => ({
          label: order.name,
          id: order.id,
        })
      );
      setFoundItems(options);
    },
    fetchPolicy: "network-only",
  });
  const { data: drugOrderInCycleData } = useQuery(DRUG_ORDER_IN_CYCLE, {
    variables: { templateId: template.id },
  });
  const handleSearchChange = (value: string, reason?: string) => {
    clearTimeout(timer.current);
    // in this case, the "reset" reason means that something was selected.
    // the selected item will be in the value param.
    setSearchTerm(reason === "reset" ? "" : value);
    timer.current = window.setTimeout(() => {
      if (!searchOpen || reason === "reset" || value === "") {
        setFoundItems([]);
        return;
      }
      getDrugOrderSearchResults({ variables: { searchTerm: value } });
    }, 250);
  };

  const addOrEditDrugOrder = (value: Option | null) => {
    const found = uniqueDrugOrders.find(
      (dose) => dose.drugOrder.id === value?.id
    );
    if (found) {
      selectDrugOrderToEdit(found);
    } else {
      selectDrugOrderToAdd(value);
    }
  };

  const selectDrugOrderToAdd = (value: Option | null) => {
    setSelectedDrugOrder(value);
    setEditingDrugOrderDays(false);
    setModalOpen(true);
  };

  const selectDrugOrderToEdit = (protocolDrugOrder: ProtocolDrugOrder) => {
    const option: Option = {
      label: protocolDrugOrder.drugOrder.name,
      id: protocolDrugOrder.drugOrder.id || "",
    };
    setSelectedDrugOrder(option);
    setEditingDrugOrderDays(true);
    setModalOpen(true);
  };

  useEffect(() => {
    const selectedCycleButton = document.getElementById(
      `cycle-button-${selectedCycle}`
    );
    if (selectedCycleButton) selectedCycleButton.scrollIntoView();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (drugOrderInCycleData) {
      const uniqueDrugOrders = drugOrderInCycleData.drugOrderInCycle
        .filter(
          (ProtocolDrugOrderInCycle: ProtocolDrugOrderInCycle) =>
            ProtocolDrugOrderInCycle.cycle.toString() === selectedCycle
        )
        .sort(
          (a: ProtocolDrugOrderInCycle, b: ProtocolDrugOrderInCycle) =>
            a.position - b.position
        )
        .map(
          (drugOrderInCycle: ProtocolDrugOrderInCycle) =>
            drugOrderInCycle.protocolDrugOrder
        );

      setUniqueDrugOrders(Object.values(uniqueDrugOrders));
    }
  }, [selectedCycle, drugOrderInCycleData]);

  const [updateCycleDrugOrderMutation] = useMutation(UPDATE_CYCLE_DRUG_ORDER, {
    refetchQueries: ["drugOrderInCycle", "Template"],
    awaitRefetchQueries: true,
    onError: () => {
      alert("Something went wrong");
    },
  });

  interface UniqueDrugOrderProps {
    protocolDrugOrder: ProtocolDrugOrder;
  }

  const UniqueDrugOrder = ({ protocolDrugOrder }: UniqueDrugOrderProps) => {
    // Vars and functions for 'Remove from Cycle' elements
    const [
      popperElement,
      setPopperElement,
    ] = React.useState<SVGSVGElement | null>(null);
    const removePopoverOpen = Boolean(popperElement);

    const removeDrugOrderFromCycle = (protocolDrugOrder: ProtocolDrugOrder) => {
      updateCycleDrugOrderMutation({
        variables: {
          templateId: template.id,
          cycle: parseInt(selectedCycle),
          drugOrderId: protocolDrugOrder?.drugOrder.id, // TODO:Change this to protocol drug order id
          days: [],
        },
      });
      setPopperElement(null);
    };
    return (
      <DrugNameCard style={{ marginTop: "8px" }}>
        <Tooltip title={protocolDrugOrder.drugOrder.name}>
          <DrugNameCardInner
            onClick={() => selectDrugOrderToEdit(protocolDrugOrder)}
          >
            {protocolDrugOrder.drugOrder.name}
          </DrugNameCardInner>
        </Tooltip>
        <RemoveDrugOrderIcon
          onClick={(event) => setPopperElement(event.currentTarget)}
        />
        <Popover
          open={removePopoverOpen}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: -40, horizontal: 12 }}
          anchorEl={popperElement}
          transitionDuration={0}
          onClose={() => setPopperElement(null)}
        >
          <div>
            Remove drug order from cycle{" "}
            {template.continuous ? "" : parseInt(selectedCycle) + 1}
            <PopoverButtons>
              <Button
                size="small"
                mode="text"
                title="Cancel"
                onClick={() => {
                  setPopperElement(null);
                }}
              ></Button>
              <Button
                size="small"
                color="destructive"
                title="Remove"
                onClick={() => {
                  removeDrugOrderFromCycle(protocolDrugOrder);
                }}
              ></Button>
            </PopoverButtons>
          </div>
        </Popover>
      </DrugNameCard>
    );
  };

  if (!drugOrderInCycleData) return <></>;
  return (
    <>
      <CycleDrugOrderModal
        daysPerCycle={template.daysPerCycle}
        template={template}
        selectedCycle={parseInt(selectedCycle)}
        open={modalOpen}
        setOpen={setModalOpen}
        drugOrder={selectedDrugOrder}
        editing={editingDrugOrderDays}
        protocolDrugOrders={uniqueDrugOrders}
      />

      <ProtocolHeader>
        <TemplateName>
          <InsertDriveFileIcon />
          {template.name}
        </TemplateName>
        <div>
          <span>
            Evidence ID <br />
            <EvidenceLink template={template}></EvidenceLink>
          </span>
        </div>
      </ProtocolHeader>

      {selectedCycle && (
        <MainContainer>
          <MainHeading>Drug Orders</MainHeading>
          <DrugOrderPanel data-test-id="drug-order-panel">
            <SearchBox
              id={"drug-order-search"}
              value={searchTerm}
              label="Add Drug Order"
              options={foundItems}
              onChange={handleSearchChange}
              onSelect={addOrEditDrugOrder}
              onOpen={() => setSearchOpen(true)}
              onClose={() => setSearchOpen(false)}
            />
            <CycleDrugOrderInfo>
              {`Cycle ${parseInt(selectedCycle) + 1} drug orders`}
              <DrugOrderCount>{uniqueDrugOrders.length}</DrugOrderCount>
            </CycleDrugOrderInfo>
            <ScrollingContainer>
              {uniqueDrugOrders.map((drugOrder) => (
                <UniqueDrugOrder
                  key={drugOrder.id}
                  protocolDrugOrder={drugOrder}
                />
              ))}
            </ScrollingContainer>
          </DrugOrderPanel>

          <CyclePanel>
            {template.continuous ? <h4>Continuous Cycle</h4> : <h4>Cycles</h4>}
            <CycleButtonBar>
              {template.continuous ? (
                <CycleButton
                  to="/1"
                  id={"cycle-button-continuous"}
                  className={"active continuous"}
                >
                  <LoopIcon fontSize={"large"} />
                </CycleButton>
              ) : (
                [...Array(template.numberOfCycles).keys()].map((cycle) => (
                  <CycleButton
                    key={cycle}
                    to={`/template/${template.id}/cycle/${cycle}`}
                    id={`cycle-button-${cycle}`}
                    data-test-id={`cycle-button-${cycle}`}
                    className={
                      cycle === parseInt(selectedCycle) ? "active" : ""
                    }
                  >
                    {cycle + 1}
                  </CycleButton>
                ))
              )}
            </CycleButtonBar>

            <CycleTable
              protocolInformation={getProtocolInformation(
                template,
                drugOrderInCycleData.drugOrderInCycle
              )}
              selectedCycle={parseInt(selectedCycle)}
              readonly={disableEditing}
            />
          </CyclePanel>
        </MainContainer>
      )}
    </>
  );
};

export default Cycle;
