import styled from "styled-components";
import { Divider } from "@mui/material";

const OarDivider = styled(Divider)`
&& {
  margin-top: 15px;
  margin-bottom: 15px;
  }
}}
`;

export default OarDivider;
